export default [
    // - ERRORS
    {
        path: '/:lang/error-404',
        name: 'error-404',
        component: () => import('@/pages/samples/error-pages/error-404'),
        meta: {
          loginRequired: false,
        },
      },
      {
        path: '/:lang/error-403',
        name: 'error-403',
        component: () => import('@/pages/samples/error-pages/error-403'),
        meta: {
          loginRequired: false,
        },
      },
      {
        path: '/:lang/unauthorized',
        component: () => import('@/pages/samples/error-pages/error-500'),
        meta: {
          loginRequired: false,
        },
      },
  ]
  
  
  