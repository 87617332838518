import { apiUrl} from '../../constants/config'
import axios from 'axios'

export default {
    state: {
        loadingAids:false,
        errorsAids:false,
        listeAids:[],
        folderAides:[],
    },
    getters: {
        errorsAids: state => state.errorsAids,
        loadingAids : state => state.loadingAids,
        listeAids : state => state.listeAids,
        folderAides : state => state.folderAides
    },
    mutations: {

        SET_LOADING_AIDES(state, payload){
            state.loadingAids = payload
        },
        SET_ERROR_AIDES(state, payload){
            state.errorsAids = payload
        },
        SET_LISTE_AIDES(state, payload){
            state.listeAids = payload
        },
        SET_FOLDER_AIDES(state, payload){
            state.folderAides = payload
        },
    },
    actions: {
        initlistAides({ commit }, payload) {
            commit("SET_LOADING_AIDES",true);
            commit("SET_LISTE_AIDES", []);
            commit("SET_ERROR_AIDES",false);
        },
        saveFolderAides(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('SET_LOADING_AIDES', true);
                axios.post(apiUrl + `/api/common/v1/folder/${payload.numCmd}/aides`, payload).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        context.commit('SET_LOADING_AIDES', false);
                    }
                    resolve(true)
                }).catch((error) => {
                    context.commit('SET_LOADING_AIDES', false);
                    console.log(error)
                    reject(error);
                })
            });
        },
        getFolderAides(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('SET_LOADING_AIDES', true);
                axios.get(apiUrl + `/api/common/v1/folder/${payload}/aides`).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        context.commit('SET_FOLDER_AIDES', response.data.data.map((item) => {
                            return {
                                ...item,
                                isDeleted: false,
                                isNew: false,
                                isCustome: false,
                            }
                        }));
                        context.commit('SET_LOADING_AIDES', false);
                    }
                    resolve(true)
                }).catch((error) => {
                    context.commit('SET_LOADING_AIDES', false);
                    console.log(error)
                    reject(error);
                })
            });
        },
        getlistAides({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_AIDES",true);
                commit("SET_LISTE_AIDES", []);
                axios.get(apiUrl+'/api/common/v1/folder/' + payload.numCmd + '/search/aids/promo/' + payload.promoCode + '/month/' + payload.month, {
                        params: {
                            year: payload?.year
                        }
                    }
                )
                    .then(res => {
                        if (res.status === 200 || res.status === 201) {
                            resolve(true)
                            commit("SET_LOADING_AIDES",false)
                            commit("SET_LISTE_AIDES",res.data);
                            commit("SET_ERROR_AIDES",false)
                        }
                    }).catch(error => {
                    commit("SET_LOADING_AIDES",false)
                    commit("SET_LISTE_AIDES",[]);
                    commit("SET_ERROR_AIDES",true)
                    reject(error)
                })

            })
        },
    },
}
