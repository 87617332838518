import { apiUrl } from '../../constants/config'
import axios from 'axios'

export default {
  state: {
    currentStep: {},
    currentStepTitle: {},
    bdcNum: {},
    docs: {},
    step_1_files: {},
    ocrData: {},
    clientType: {},
    aidesAnswers: [],
    remisesAnswers: [],
    ventAddAnswers: [],
    primesAnswers: [],
    ventAddResponses: [],
    step_1: {},
    step_2: {},
    step_3: {},
    step_4: {},
    step_5: {},
    commissions: {},
    attachmentInfos: {},
    fundingCommissions: {},
    transfo: {},
    accessoires: {},
    isCommissionFeesCalculated: {},
    gestionId: {},
    isBdcFileChanged: {},
    datesCle: [],
    gestion: [],
    isStepSaved: false,
    step_2_dispatched: false,
    step_3_dispatched: false,
    step_4_dispatched: false,
    step_5_dispatched: false,
    cmdStatut: {},
    clientOptions: [],
    questions_step_1: [],
    file: {},
    creationDocsResponses: {},
    isClientPhoneSetUP: {},
    isNSirenSetUP: {},
    isClientSpaceSetUP: false,
    renderAddBdcComponent: false,
    aidesData:{},
    filterCommandeParams:{},
    clientDetails:[],
    datesCles:{},
    cmdNumber:'',
    dossierType: '',
    prestationsList: [],
    selectedPrestationsList: [],
    msgCreated:false
  },
  getters: {
    step_1: (state) => state.step_1,
    step_1_files: (state) => state.step_1_files,
    step_2: (state) => state.step_2,
    step_3: (state) => state.step_3,
    step_4: (state) => state.step_4,
    step_5: (state) => state.step_5,
    clientType: (state) => state.clientType,
    // LAST STEP BEFORE LEAVING COMPONENTa
    currentStep: (state) => state.currentStep,
    currentStepTitle: (state) => state.currentStepTitle,
    bdcNum: (state) => state.bdcNum,
    docs: (state) => state.docs,
    ocrData: (state) => state.ocrData,
    commissions: (state) => state.commissions,
    attachmentInfos: (state) => state.attachmentInfos,
    fundingCommissions: (state) => state.fundingCommissions,
    transfo: (state) => state.transfo,
    accessoires: (state) => state.accessoires,
    isCommissionFeesCalculated: (state) => state.isCommissionFeesCalculated,
    gestionId: (state) => state.gestionId,
    isBdcFileChanged: (state) => state.isBdcFileChanged,
    aidesAnswers: (state) => state.aidesAnswers,
    remisesAnswers: (state) => state.remisesAnswers,
    ventAddAnswers: (state) => state.ventAddAnswers,
    primesAnswers: (state) => state.primesAnswers,
    datesCle: (state) => state.datesCle,
    gestion: (state) => state.gestion,
    isStepSaved: (state) => state.isStepSaved,
    step_2_dispatched: (state) => state.step_2_dispatched,
    step_3_dispatched: (state) => state.step_3_dispatched,
    step_4_dispatched: (state) => state.step_4_dispatched,
    step_5_dispatched: (state) => state.step_5_dispatched,
    cmdStatut: (state) => state.cmdStatut,
    clientOptions: (state) => state.clientOptions,
    questions_step_1: (state) => state.questions_step_1,
    file: (state) => state.file,
    creationDocsResponses: (state) => state.creationDocsResponses,
    ventAddResponses: (state) => state.ventAddResponses,
    isClientPhoneSetUP: (state) => state.isClientPhoneSetUP,
    isNSirenSetUP: (state) => state.isNSirenSetUP,
    isClientSpaceSetUP: (state) => state.isClientSpaceSetUP,
    renderAddBdcComponent: (state) => state.renderAddBdcComponent,
    aidesData : (state) => state.aidesData,
    filterCommandeParams: (state) => state.filterCommandeParams,
    clientDetails : (state) => state.clientDetails,
    datesCles : (state) => state.datesCles,
    cmdNumber : (state) => state.cmdNumber,
    dossierType : (state) => state.dossierType,
    prestationsList : (state) => state.prestationsList,
    selectedPrestationsList : (state) => state.selectedPrestationsList,
    messageCreated : (state) => state.msgCreated
  },

  mutations: {
    step_1_files(state, payload) {
      state.step_1_files = payload
    },
    step_1(state, payload) {
      state.step_1 = payload
    },
    step_2(state, payload) {
      state.step_2 = payload
    },
    step_3(state, payload) {
      state.step_3 = payload
    },
    step_4(state, payload) {
      state.step_4 = payload
    },
    step_5(state, payload) {
      state.step_5 = payload
    },
    currentStep(state, payload) {
      state.currentStep = payload
    },
    currentStepTitle(state, payload) {
      state.currentStepTitle = payload
    },
    bdcNum(state, payload) {
      state.bdcNum = payload
    },
    docs(state, payload) {
      state.docs = payload
    },
    clientType(state, payload) {
      state.clientType = payload
    },
    ocrData(state, payload) {
      state.ocrData = payload
    },
    commissions(state, payload) {
      state.commissions = payload
    },
    attachmentInfos(state, payload) {
      state.attachmentInfos = payload
    },
    fundingCommissions(state, payload) {
      state.fundingCommissions = payload
    },
    transfo(state, payload) {
      state.transfo = payload
    },
    accessoires(state, payload) {
      state.accessoires = payload
    },
    isCommissionFeesCalculated(state, payload) {
      state.isCommissionFeesCalculated = payload
    },
    gestionId(state, payload) {
      state.gestionId = payload
    },
    isBdcFileChanged(state, payload) {
      state.isBdcFileChanged = payload
    },
    aidesAnswers(state, payload) {
      state.aidesAnswers = payload
    },
    remisesAnswers(state, payload) {
      state.remisesAnswers = payload
    },
    ventAddAnswers(state, payload) {
      state.ventAddAnswers = payload
    },
    primesAnswers(state, payload) {
      state.primesAnswers = payload
    },
    datesCle(state, payload) {
      state.datesCle = payload
    },
    gestion(state, payload) {
      state.gestion = payload
    },
    isStepSaved(state, payload) {
      state.isStepSaved = payload
    },
    step_2_dispatched(state, payload) {
      state.step_2_dispatched = payload
    },
    step_3_dispatched(state, payload) {
      state.step_3_dispatched = payload
    },
    step_4_dispatched(state, payload) {
      state.step_4_dispatched = payload
    },
    step_5_dispatched(state, payload) {
      state.step_5_dispatched = payload
    },
    cmdStatut(state, payload) {
      state.cmdStatut = payload
    },
    clientOptions(state, payload) {
      state.clientOptions = payload
    },
    questions_step_1(state, payload) {
      state.questions_step_1 = payload
    },
    file(state, payload) {
      state.file = payload
    },
    creationDocsResponses(state, payload) {
      state.creationDocsResponses = payload
    },
    ventAddResponses(state, payload) {
      state.ventAddResponses = payload
    },
    isClientPhoneSetUP(state, payload) {
      state.isClientPhoneSetUP = payload
    },
    isNSirenSetUP(state, payload) {
      state.isNSirenSetUP = payload
    },
    isClientSpaceSetUP(state, payload) {
      state.isClientSpaceSetUP = payload
    },
    renderAddBdcComponent(state, payload) {
      state.renderAddBdcComponent = payload
    },
    SET_AIDES_DATA(state, payload){
      state.aidesData = payload
    },
    SAVE_FILTER_COMMANDES_PARAMS(state, payload){
      state.filterCommandeParams = payload
    },
    SET_CLIENT_DETAILS(state, payload){
      state.clientDetails = payload
    },
    SET_DATE_CLE(state, payload){
      state.datesCles = payload
    },
    SET_CMD_NUMBER(state, payload){
      state.cmdNumber = payload
    },
    SET_DOSSIER_TYPE(state, payload){
      state.dossierType = payload
    },
    SET_PRESTATION_LIST(state, payload){
      state.prestationsList = payload
    },
    SET_SELECTED_PRESTATION_LIST(state, payload){
      state.selectedPrestationsList = payload
    },
    SET_MESSAGE_CREATED(state, payload){
      state.msgCreated = payload
    },
  },
  actions: {
    getClientTypeList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .get(apiUrl + "/api/admin/v1/type-clients")
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                commit('clientOptions', response.data)
              }
              resolve(true)
            })
            .catch((error) =>  reject(error));
      });

    },
    resetStore({ commit }) {
      commit('currentStep', {}),
        commit('currentStepTitle', {}),
        commit('bdcNum', {}),
        commit('docs', {}),
        commit('step_1_files', {}),
        commit('ocrData', {}),
        commit('clientType', {}),
        commit('isClientSpaceSetUP', false)
        commit('step_1', {}),
        commit('step_2', {}),
        commit('step_3', {}),
        commit('step_4', {}),
        commit('step_5', {}),
        commit('commissions', {}),
        commit('attachmentInfos', {}),
        commit('fundingCommissions', {}),
        commit('transfo', {}),
        commit('accessoires', {}),
        commit('isCommissionFeesCalculated', {}),
        commit('gestionId', {}),
        commit('isBdcFileChanged', {}),
        commit('step_1_files', {})
        commit('cmdStatut', {}),
        commit('aidesAnswers', {}),
        commit('ventAddAnswers', {})
        commit('remisesAnswers', {})
        commit('renderAddBdcComponent', false),
        commit('primesAnswers',{}),
        commit('gestion', {})
    },
    setGestionId({ commit }, payload) {
      commit('gestionId', payload)
    },
    bonCommande({ commit }, payload) {
      if (payload.storageStep) {
        commit('currentStep', payload)
      } else {
        switch (payload.step) {
          case '1_1':
            commit('step_1', payload)
            break
          case '1_2':
            commit('step_1_files', payload)
            break
          case '2':
            commit('step_2', payload)
            break
          case '3':
            commit('step_3', payload)
            break
          case '4':
            commit('step_4', payload)
            break
          case '5':
            commit('step_5', payload)
            break
          default:
        }
      }
    },
    saveStep({ commit }, payload) {
      if (payload.step) {
        commit('currentStepTitle', payload)
      }
    },
    setClientType({ commit }, payload) {
      if (payload) {
        commit('clientType', payload)
      }
    },
    saveCreatedBdcNum({ commit }, payload) {
      commit('bdcNum', payload)
    },
    setfilesList({ commit }, payload) {
      commit('docs', payload)
    },
    setComissionsStatus({ commit }, payload) {
      commit('isCommissionFeesCalculated', payload)
    },
    // Get QrCode
    getQrCode({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .post(`${apiUrl}/api/common/v1/folder/generate/qrcode`, payload)
            .then((response) => {
              if (response?.status === 200 || response?.status === 201) {
                resolve(response.data);
              }
            })
            .catch((error) => {
              reject(error);
            })
      });
    },
    // SAUVGARDE WF STEP NUMBER
    saveCurrentStep({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/api/common/v1/wfc_lnk_step_profil`, payload)
          .then(
            ({ data, status }) => {
              if (status === 200) {
                resolve(true)
              }
            },
            (err) => {
              commit('setError', err.response.data.message)
              setTimeout(() => {
                commit('clearError')
              }, 3000)
            },
          )
          .catch((error) => {
            // reject(error);
          })
      })
    },
   async setCommissions({ commit }, payload) {
     await  commit('commissions', payload)
    },
    setTransfoCommissions({ commit }, payload) {
      commit('transfo', payload)
    },
    setAccCommissions({ commit }, payload) {
      commit('accessoires', payload)
    },
    saveDataSheetCartouche({ commit }, payload) {
      commit('attachmentInfos', payload)
    },
    saveOCRData({ commit }, payload) {
      if (payload) {
        commit('ocrData', payload)
      }
    },
    saveFundingType({ commit }, payload) {
      if (payload) {
        commit('fundingCommissions', payload)
      }
    },
    setFileStatus({ commit }, payload) {
      commit('isBdcFileChanged', payload)
    },
    async   setAidesResponses({ commit }, payload) {
    await  commit('aidesAnswers', payload)
    },
    async setRemisesResponses({ commit }, payload) {
      await commit('remisesAnswers', payload)
    },
    async setPrimesResponses({ commit }, payload) {
      await commit('primesAnswers', payload)
    },
    async setVentAddResponses({ commit }, payload) {
     await commit('ventAddAnswers', payload)
    },
   async  setVentResponses({ commit }, payload) {
    await  commit('ventAddResponses', payload)
    },
    async  setCreationDocsResponses({ commit }, payload) {
    await  commit('creationDocsResponses', payload)
    },
    setDateCle({ commit }, payload) {
      commit('datesCle', payload)
    },
    setGestion({ commit }, payload) {
      commit('gestion', payload)
    },
    isStepSaved({ commit }, payload) {
      commit('isStepSaved', payload)
    },
    setSecondStepTwoAnswerDispatched({ commit }, payload) {
      commit('step_2_dispatched', payload)
    },
    setThirdStepAnswerDispatched({ commit }, payload) {
      commit('step_3_dispatched', payload)
    },
    setFourthStepAnswerDispatched({ commit }, payload) {
      commit('step_4_dispatched', payload)
    },
    setFivethStepAnswerDispatched({ commit }, payload) {
      commit('step_5_dispatched', payload)
    },
   async saveStatus({ commit }, payload) {
      await commit('cmdStatut', payload)
    },
    setClientOptions({ commit }, payload) {
      commit('clientOptions', payload)
    },
    questionsFirstStep({ commit }, payload) {
      commit('questions_step_1', payload)
    },
    setUploadedFile({ commit }, payload) {
      commit('file', payload)
    },
    setClientPhone({ commit }, payload) {
      commit('isClientPhoneSetUP', payload)
    },
    setNSiren({ commit }, payload) {
      commit('isNSirenSetUP', payload)
    },
    setIsClientSpaceCreated({ commit }, payload) {
      commit('isClientSpaceSetUP', payload)
    },
    refrechComponent({ commit }, payload) {
      commit('renderAddBdcComponent', payload)
    },
    createVialinkControl(context, payload){
      axios.post(`${apiUrl}/api/admin/v1/vialink/create-control`,payload)
      .then(({ data, status }) => {
          if (status === 201 || status === 204) {
           context.commit('isClientSpaceSetUP', true)
          }
      },)
      .catch(error => {
       console.log('errr', error)
      })
  },
    // SAVE COMMISSIONS VALUES.
    saveCommissions({ commit }, payload) {
        axios
          .post(
            apiUrl + '/api/common/v1/save/commission',
           payload
          )
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
            //  console.log("response ==>", response)
            }
          })
          .catch((error) => {
            console.log(error.message)
          })

    },
    getAidesData(context, payload){
      axios
        .get(apiUrl + '/api/common/v1/dossier_gestion_des_aides', {
          params: {
            type_client: payload,
          },
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            context.commit('SET_AIDES_DATA', response.data)
          }
        })
        .catch((error) => console.log(error))

    }

  },
}
