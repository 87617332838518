import axios from "axios";
import {apiUrl} from "@/constants/config";

export default {
    state: {
        loadingBatch: false,
        loadingBatchByUser: false,
        loadingAddBatch: false,
        listBatch: [],
        listBatchByUser: [],
    },
    getters: {
        loadingBatchByUser: state => state.loadingBatchByUser,
        loadingAddBatch: state => state.loadingAddBatch,
        listBatchByUser: state => state.listBatchByUser,
        loadingBatch: state => state.loadingBatch,
        listBatch: state => state.listBatch,
    },
    mutations: {
        SET_INIT_BACH_BY_CLIENT(state, payload) {
            state.loadingBatchByUser = false;
            state.listBatchByUser = [];
        },
        SET_BACH_BY_CLIENT(state, payload) {
            state.listBatchByUser = payload;
        },SET_BACH(state, payload) {
            state.listBatch = payload;
        },
        SET_LOADING_BACH_BY_CLIENT(state, payload) {
            state.loadingBatchByUser = payload;
        },
        SET_LOADING_BACH(state, payload) {
            state.loadingBatch = payload;
        },
        SET_LOADING_ADD_BACH(state, payload) {
            state.loadingAddBatch = payload;
        },
    },
    actions: {
        initBatchByClientData({commit}) {
            commit("SET_INIT_BACH_BY_CLIENT", []);
        },
        getBatch({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_BACH", true);
                axios.get(apiUrl + `/api/admin/v1/batch/${payload?.refSite}`, {
                    //  params: payload
                }).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_BACH", response.data.data);
                    }
                    commit("SET_LOADING_BACH", false);
                    resolve(response.data.data)
                }).catch((error) => {
                    reject(error);
                    commit("SET_LOADING_BACH", false);
                })
            })
        },
        getBatchByClient({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_BACH_BY_CLIENT", true);
                axios.get(apiUrl + `/api/admin/v1/folders/client/${payload.client}/batch`, {
                  //  params: payload
                }).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_BACH_BY_CLIENT", response.data.data);
                    }
                    commit("SET_LOADING_BACH_BY_CLIENT", false);
                    resolve(true)
                }).catch((error) => {
                    reject(error);
                    commit("SET_LOADING_BACH_BY_CLIENT", false);
                })
            })
        },
        addBatchByClient({commit,dispatch}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_BACH_BY_CLIENT", true);
                axios.post(apiUrl + `/api/admin/v1/folders/client/${payload.client}/batch`, payload).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_BACH_BY_CLIENT", response.data.data);
                    }
                    commit("SET_LOADING_BACH_BY_CLIENT", false);
                    resolve(true)
                }).catch((error) => {
                    reject(error);
                    commit("SET_LOADING_BACH_BY_CLIENT", false);
                })
            })
        },
        addBatch({commit,dispatch}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_ADD_BACH", true);
                axios.post(apiUrl + `/api/admin/v1/batch`, payload).then((response) => {
                    commit("SET_LOADING_ADD_BACH", false);
                    resolve(response)
                }).catch((error) => {
                    reject(error);
                    commit("SET_LOADING_ADD_BACH", false);
                })
            })
        },
        deleteBatchByClient({commit,dispatch}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_BACH_BY_CLIENT", true);
                axios.delete(apiUrl + `/api/admin/v1/folders/client/${payload.client}/batch/${payload.batch}`, {
                    params: payload
                }).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_BACH_BY_CLIENT", response.data.data);
                        dispatch('getBatchByClient', payload);
                    }
                    commit("SET_LOADING_BACH_BY_CLIENT", false);
                    resolve(true)
                }).catch((error) => {
                    reject(error);
                    commit("SET_LOADING_BACH_BY_CLIENT", false);
                })
            })
        },
        getListBatch({commit,dispatch}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_ADD_BACH", true);
                axios.get(apiUrl + `/api/admin/v1/batch`, {params:payload}).then((response) => {
                    commit("SET_LOADING_ADD_BACH", false);
                    resolve(response.data.data)
                }).catch((error) => {
                    reject(error);
                    commit("SET_LOADING_ADD_BACH", false);
                })
            })
        },
    }
}
