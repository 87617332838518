import { apiUrl } from '../../constants/config'
import axios from 'axios'

export default {
  state: {
    answersList:{data:[]},
    dossierID:null,
    newChangesAppeared:false,
    steps:[],
    isAllRequiredFieldsFilled:null,
    alertFillBlanks:null,
    questionsAnswersList:[],
    successExecution:false,
    loadingAction: false,
    gestionDetails: {}
  },
  getters: {
    answersList: state => state.answersList,
    dossierID: state => state.dossierID,
    newChangesAppeared: state => state.newChangesAppeared,
    steps: state => state.steps,
    isAllRequiredFieldsFilled: state =>state.isAllRequiredFieldsFilled,
    alertFillBlanks: state => state.alertFillBlanks,
    questionsAnswersList: state => state.questionsAnswersList,
    successExecution: state => state.successExecution,
    loadingAction: state => state.loadingAction,
    gestionDetails: state => state.gestionDetails,
  },
  mutations: {
    SET_ANSWERS_STEP(state, payload){
      state.answersList = payload ;
    },
    SET_DOSSIER_ID(state, payload){
      state.dossierID = payload ;
    },
    IS_NEW_CHANGES_APPEARED(state, payload){
      state.newChangesAppeared = payload ;
    },
    SET_QUESTIONS_STEPS(state, payload){
        state.steps = payload ;
      },
    SET_REQUIRED_FIELDS_STATUS(state, payload){
        state.isAllRequiredFieldsFilled = payload ;
      },
    HANDLE_ALERT_NOTE(state, payload){
        state.alertFillBlanks = payload ;
      },
    SET_QUESTIONS_ANSWERS_LIST(state, payload){
        state.questionsAnswersList = payload ;
      },
    SET_SUCCESS(state,payload){
        state.successExecution = payload
    },
    SET_LOADING(state,payload){
        state.loadingAction = payload
    },
    SET_GESTION_DETAILS(state,payload){
        state.gestionDetails = payload
    },
  },
  actions: {
    // SAVE WF QUESTIONS ANSWERS.
    saveResponse({commit}, payload){
      return new Promise((resolve, reject) => {
      commit('SET_LOADING',true);
        axios
          .post(apiUrl + '/api/common/v1/store_response', payload)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              commit('SET_SUCCESS',true)
              commit('SET_LOADING', false)
              commit('SET_ANSWERS_STEP', {data:[]})
              commit('SET_GESTION_DETAILS', response.data.folder);
            }
            resolve(response)
          })
          .catch((error) => {
            commit('SET_LOADING',false)
            console.log(error.message)
            reject(error);
          })
      });
    },

    getCmdsList({commit}, payload) {
     return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/api/admin/v1/folders", { params: payload })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            resolve(response);
          }
        }).catch(function(error) {
          console.log("Error ==>", error)
          reject(error);
        });
      });

    }


  },
}
