export default [
    // - AUTH
    {
        path: '/:lang/login',
        name: 'login',
        component: () => import('@/pages/user/Login'),
        meta: {
          loginRequired: false,
        },
      },
      {
        path: '/:lang/forgot-password',
        name: 'forgotPassword',
        component: () => import('@/pages/user/ForgotPassword'),
        meta: {
          loginRequired: false,
        },
      },
     
      {
        path: '/:lang/reset-password',
        name: 'resetPassword',
        component: () => import('@/pages/user/ResetPassword'),
        meta: {
          loginRequired: false,
        },
      },
  ]
  
  
  