import { apiUrl, isAuthGuardActive } from "../../constants/config";
import moment from "moment";
import axios from "axios";
export default {
  state: {
    loadingPlanning: false,
    planningWeek: {},
    planningMonth: {},
    planningDay: [],
    availability: [],
    prestations: [],
    showLoadError: false,
    commandesList: [],
    foldersList: [],
    actionsList: [],
    loading: false,
    loadingDeleteReservation: false,
    filtedActionList: [],
    allActionsList: [],
    actionsLabels: [],
    nextFolders: [],
    loadingFolders: false,
    allCalendarFolder: [],
  },
  getters: {
    loadingPlanning: (state) => state.loadingPlanning,
    loadingDeleteReservation: (state) => state.loadingDeleteReservation,
    planningWeek: (state) => state.planningWeek,
    planningDay: (state) => state.planningDay,
    availability: (state) => state.availability,
    planningMonth: (state) => state.planningMonth,
    prestations: (state) => state.prestations,
    showLoadError: (state) => state.showLoadError,
    commandesList: (state) => state.commandesList,
    loading: (state) => state.loading,
    actionsList: (state) => state.actionsList,
    filtedActionList: (state) => state.filtedActionList,
    allActionsList: (state) => state.allActionsList,
    actionsLabels: (state) => state.actionsLabels,
    foldersList: (state) => state.foldersList,
    nextFolders: (state) => state.nextFolders,
    loadingFolders: (state) => state.loadingFolders,
    allCalendarFolder: (state) => state.allCalendarFolder,
  },
  mutations: {
    UPDATE_LOADING(state, payload) {
      state.loadingPlanning = payload;
    },
    UPDATE_PLANNING_DAY(state, payload) {
      state.planningDay = payload;
    },
    UPDATE_PLANNING_WEEK(state, payload) {
      state.planningWeek = payload;
    },
    UPDATE_PLANNING_MONTH(state, payload) {
      state.planningMonth = payload;
    },
    UPDATE_AVAILABILITY(state, payload) {
      state.availability = payload;
    },
    UPDATE_PRESTATIONS(state, payload) {
      state.prestations = payload;
    },
    UPDATE_SHOW_ERROR(state, payload) {
      state.showLoadError = payload;
    },
    SET_FOLDERS_LIST(state, payload) {
      state.commandesList = payload;
    },
    SET_LOADING_DELETE_RESERVATION(state, payload) {
      state.loadingDeleteReservation = payload;
    },
    SET_FOLDERS_DIST_LIST(state, payload) {
      state.foldersList = payload;
    },
    UPDATE_FOLDER_ITEM(state, payload) {
      state.commandesList.map((item, index) => {
        if (item.id == payload.id) {
          if (payload.item.data.vehicule)
            state.commandesList[index].vehicule.livraison = {
              ...payload.item.data.vehicule.livraison,
            };
          state.commandesList[index].statut = { ...payload.item.data.statut };
        }
      });
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_FOLDERS_NEXT_LIST(state, payload) {
      state.nextFolders = payload;
    },
    UPDATE_ACTIONS_LIST(state, payload) {
      state.actionsList = [...payload];
    },
    FILTERED_ACTIONS_LIST(state, payload) {
      state.filtedActionList = [...payload];
    },
    ALL_ACTIONS_LIST(state, payload) {
      state.allActionsList = [...payload];
    },
    CHECK_ACTION(state, payload) {
      state.allActionsList.map((item) => {
        if (item.message_id == payload.message) {
          item.is_read = !item.is_read;
        }
      });
      state.filtedActionList = [
        ...state.allActionsList.filter((item) => item.is_read == false),
      ];
      if (payload.checked) {
        state.actionsList = [...state.allActionsList];
      } else {
        state.actionsList = [...state.filtedActionList];
      }
    },
    ALL_LABELS_LIST(state, payload) {
      state.actionsLabels = [...payload];
    },
    SET_SUB_LOADING(state, payload) {
      state.loadingFolders = payload;
    },
    RESET_ACTIONS_LIST(state, payload) {
      (state.filtedActionList = []),
        (state.allActionsList = []),
        (state.actionsList = []);
    },
    SET_ALL_CALENDAR_fOLDERS(state, payload) {
      state.allCalendarFolder = payload;
    },
  },
  actions: {
    async getPlanning(context, payload) {
      context.commit("UPDATE_SHOW_ERROR", false);
      if (payload.load) {
        context.commit("UPDATE_LOADING", true);
      }
      await axios
        .get(apiUrl + `/api/admin/v1/calendar/${payload.ref_site}`)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            let dayEvents = [];
            let weekEvents = [];
            let MonthEvents = [];
            context.commit("SET_ALL_CALENDAR_fOLDERS", response.data);
            response.data.map((item, index) => {
              //if (item.date_livraison_proposition) {
                // day Item
                let start = item.date_livraison_valide
                  ? item.date_livraison_valide
                  : item.date_livraison_proposition;
                let type = item.date_livraison_valide ? "valid" : "proposed";
                let end = moment(start, "YYYY-MM-DD HH:mm:ss")
                  .add(item.prestation.dureée_prestation, "minutes")
                  .format("YYYY-MM-DD HH:mm:ss");
                let dayItem = {
                  id: item.id,
                  title: item.vin ? item.vin.slice(-8, item.vin.length) : "-",
                  nom: item.client.nom_prenom
                    ? item.client.nom_prenom
                    : item.client.prenom + "" + item.client.nom,
                  cmd: item.numero_commande,
                  type: type,
                  start: start,
                  end: end,
                  code_zone: item.immat,
                  allDay: false,
                  view: "day",
                  energie: item.energie,
                  accessoire: item.options,
                  modele: item.modele ? item.modele : "-",
                  couleur: item.couleur_vehicule ,
                  phone: item.client.tel_portable,
                  mise_en_main: item.prestation.mise_en_main,
                  departement: item.departement,
                  evt_duration: item.prestation.dureée_prestation,
                  type_client: item.client.type_id,
                  id_prestation: item.prestation.id,
                  nom_prestation: item.prestation.libelle_prestation,
                  color_prestation: item.prestation.color_prestation,
                  vendeur_nom: item.vendeur.last_name,
                  vendeur_prenom: item.vendeur.first_name
                }

                if (type == "valid") {

                  console.log ("ici", item.vendeur.last_name)

                  dayItem.disableDragging = true;
                  dayItem.editable = false;
                }
                dayEvents.push(dayItem);
                // week Item
                let week_start = item.date_livraison_valide
                  ? moment(item.date_livraison_valide).format("YYYY-MM-DD HH")
                  : moment(item.date_livraison_proposition).format(
                      "YYYY-MM-DD HH"
                    );
                let week_end = moment(week_start, "YYYY-MM-DD HH")
                  .add(1, "hours")
                  .format("YYYY-MM-DD HH");
                let weekindex = weekEvents.findIndex(
                  (el) =>
                    moment(el.start_label).format("YYYY-MM-DD HH") ===
                    week_start
                );
                if (weekindex > -1) {
                  weekEvents[weekindex].nb_veh++;
                  item.date_livraison_valide
                    ? weekEvents[weekindex].valid++
                    : weekEvents[weekindex].proposition++;
                  weekEvents[weekindex].per = Math.trunc(
                    (weekEvents[weekindex].valid /
                      (weekEvents[weekindex].valid +
                        weekEvents[weekindex].proposition)) *
                      100
                  );
                  let old = moment(weekEvents[weekindex].start_label).format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  if (item.date_livraison_valide) {
                    if (
                      moment(item.date_livraison_valide).isBefore(old) ||
                      moment(item.date_livraison_valide).isSame(old)
                    ) {
                      weekEvents[weekindex].start_label =
                        item.date_livraison_valide;
                      weekEvents[weekindex].id = item.id;
                    }
                  } else {
                    if (
                      moment(item.date_livraison_proposition).isBefore(old) ||
                      moment(item.date_livraison_proposition).isSame(old)
                    ) {
                      weekEvents[weekindex].start_label =
                        item.date_livraison_proposition;
                      weekEvents[weekindex].id = item.id;
                    }
                  }
                } else {
                  weekEvents.push({
                    start_label: item.date_livraison_valide
                      ? item.date_livraison_valide
                      : item.date_livraison_proposition,
                    start: week_start + ":00:00",
                    nb_veh: 1,
                    per: item.date_livraison_valide ? 100 : 0,
                    end: week_end + ":00:00",
                    allDay: false,
                    view: "week",
                    valid: item.date_livraison_valide ? 1 : 0,
                    proposition: item.date_livraison_valide ? 0 : 1,
                    editable: false,
                    id: item.id,
                    color_prestation :item.prestation.color_prestation
                  });
                }
                // Month Item
                let month_start = item.date_livraison_valide
                  ? moment(item.date_livraison_valide).format("YYYY-MM-DD")
                  : moment(item.date_livraison_proposition).format(
                      "YYYY-MM-DD"
                    );
                let Monthindex = MonthEvents.findIndex(
                  (el) => el.start === month_start + " 08:00:00"
                );
                if (Monthindex > -1) {
                  MonthEvents[Monthindex].nb_veh++;
                  item.date_livraison_valide
                    ? MonthEvents[Monthindex].valid++
                    : MonthEvents[Monthindex].proposition++;
                  MonthEvents[Monthindex].per = Math.trunc(
                    (MonthEvents[Monthindex].valid /
                      (MonthEvents[Monthindex].valid +
                        MonthEvents[Monthindex].proposition)) *
                      100
                  );
                  let old = moment(MonthEvents[Monthindex].start_label).format(
                    "YYYY-MM-DD  HH:mm:ss"
                  );
                  if (item.date_livraison_valide) {
                    if (
                      moment(item.date_livraison_valide).isBefore(old) ||
                      moment(item.date_livraison_valide).isSame(old)
                    ) {
                      MonthEvents[Monthindex].start_label =
                        item.date_livraison_valide;
                      MonthEvents[Monthindex].id = item.id;
                    }
                  } else {
                    if (
                      moment(item.date_livraison_proposition).isBefore(old) ||
                      moment(item.date_livraison_proposition).isSame(old)
                    ) {
                      MonthEvents[Monthindex].start_label =
                        item.date_livraison_proposition;
                      MonthEvents[Monthindex].id = item.id;
                    }
                  }
                } else {
                  MonthEvents.push({
                    start_label: item.date_livraison_valide
                      ? item.date_livraison_valide
                      : item.date_livraison_proposition,
                    start: month_start + " 08:00:00",
                    nb_veh: 1,
                    per: item.date_livraison_valide ? 100 : 0,
                    end: month_start + " 09:00:00",
                    allDay: false,
                    view: "month",
                    valid: item.date_livraison_valide ? 1 : 0,
                    proposition: item.date_livraison_valide ? 0 : 1,
                    editable: false,
                    id: item.id,
                    color_prestation :item.prestation.color_prestation
                  });
                }
          //    }
            });
            context.commit("UPDATE_PLANNING_DAY", dayEvents);
            context.commit("UPDATE_PLANNING_WEEK", weekEvents);
            context.commit("UPDATE_PLANNING_MONTH", MonthEvents);
            return true;
          }
        })
        .catch((error) => {
          console.log(error, "error");
          context.commit("UPDATE_LOADING", false);
          context.commit("UPDATE_SHOW_ERROR", true);
        });
      await context.dispatch("getListMessagesBySite", {
        site: payload.ref_site_msg,
      });
      context.commit("UPDATE_LOADING", false);
    },
    async getCalendar(context, payload) {
      let params = {
        config: {
          prestation: payload.prestation,
          date: payload.date,
        },
      };
      await axios
        .post(apiUrl + "/api/admin/v1/calendar", params.config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            let aivalableTimes = [];
            response.data.map((item) => {
              if (item.date == payload.date) {
                item.slots.map((singleSlot) => {
                  if (singleSlot.free) {
                    aivalableTimes.push(
                      moment(singleSlot.datetime).format("HH:mm:ss")
                    );
                  }
                });
              }
            });
            context.commit("UPDATE_AVAILABILITY", aivalableTimes);
            context.commit("SET_AVAILBLES_DATES_LIST", response.data);
            return true;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    deleteReservation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING_DELETE_RESERVATION",true)
        axios
            .delete(apiUrl + `/api/admin/v1/calendar/reservation/${payload.id}`)
            .then((response) => {
              commit("SET_LOADING_DELETE_RESERVATION",false);
              resolve(response);
            }).catch((err) => {
          reject(err);
          commit("SET_LOADING_DELETE_RESERVATION",false)
            });
      });
    },
    getReservationPrestation(context, payload) {
      let params = {
        config: {
          reservation: payload.reservation,
          ref_site: payload.ref_site,
          numero_cmd: payload.numero_cmd,
        },
      };
      axios
        .post(apiUrl + "/api/admin/v1/calendar/reservation/test", params.config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            context.commit("UPDATE_PRESTATIONS", response.data);
          }
        });
    },
    getfoldersListByStatu({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let params = {
          statut_id: [payload.statu],
          ref_site: [payload.site],
          search: payload.search,
          folder_type: payload.folder_type,
          limit: payload.limit,
          page: payload.page,
        };

        axios
            .get(apiUrl + "/api/admin/v1/folders", { params: params })
            .then((response) => {
              resolve(response)
            })
            .catch((error) => {
              reject(error);
            });
      })
    },
    getfoldersListByStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        let reqs = payload.status.map((statu)=>{
          let params = {
            statut_id: [statu],
            ref_site: [payload.site],
            // limit:20
            search: payload.search,
            folder_type: payload.folder_type,
            limit: payload.limit,
            page: payload.page,
          };
          return axios.get(apiUrl + "/api/admin/v1/folders", { params: params });
        })

        axios.all(reqs).then(axios.spread((...res)=>{
          var obj = [];
          let args = payload.status.map((statu, index)=>{

            obj['statu_'+statu] = res[index].data
          });
          resolve(obj);
          commit("SET_LOADING", false);
        })).catch((err)=>{
          reject(err);
          commit("SET_LOADING", false);
        })
      })

    },
    // GET FOLDER LIST : RUBRIC AFFECTATION PORTEFUILLE
    getfoldersList({ commit }, payload) {
      commit("SET_LOADING", true);
      let params = {
        statut_id: payload.filter,
        ref_site: [payload.site],
        // limit:20
        search: payload.search,
        folder_type: payload.folder_type,
        limit: payload.limit,
        page: payload.page,
      };
      axios
        .get(apiUrl + "/api/admin/v1/folders", { params: params })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            commit("SET_FOLDERS_LIST", response.data.data);
            commit("SET_LOADING", false);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false);
        });
    },
    invoiceWithoutFolderAffectAction({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .post(apiUrl + '/api/admin/v1/invoice/'+ payload.idFacture+'/affect-to-folders' , payload)
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                commit("SET_LOADING", false);
              }
              resolve(response)
            })
            .catch((error) => {
              reject(error)
              commit("SET_LOADING", false);
            });
      });

    },
    getInvoiceWithoutFolder({ commit }, payload) {
      //invoices/site/{refSite}/without-folders
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
            .get(apiUrl + '/api/admin/v1/invoices/site/' + payload.ref_site + '/without-folders' , { params: payload })
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                commit("SET_FOLDERS_DIST_LIST", response.data);
                commit("SET_LOADING", false);
              }
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
              commit("SET_LOADING", false);
            });
      });

    },
    // GET FOLDER LIST : RUBRIC AFFECTATION PORTEFUILLE
    getfoldersListByDistrubution({ commit }, payload) {
      let params = payload;
      commit("SET_LOADING", true);
      let uri = (payload.distrib==1)?"/api/admin/v1/folders/listDistrib":"/api/admin/v1/folders/listByStep"
      axios
        .get(apiUrl + uri , { params: params })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
              commit("SET_FOLDERS_DIST_LIST", response.data);
              commit("SET_LOADING", false);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false);
        });
    },
    // GET FOLDER LIST : RUBRIC AFFECTATION PORTEFUILLE
    loadfoldersList({ commit }, payload) {
      let params = payload;
      let uri = (payload.distrib==1)?"/api/admin/v1/folders/listDistrib":"/api/admin/v1/folders/listByStep"
      commit("SET_SUB_LOADING", true);
      axios
        .get(apiUrl + uri, { params: params })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if(response.data&& response.data.length>0&&response.data[0]?.invoices.data){
              commit("SET_FOLDERS_NEXT_LIST", response.data[0].invoices.data);
            }else {
              commit("SET_FOLDERS_NEXT_LIST", response.data.data, payload.statut);
            }
            commit("SET_SUB_LOADING", false);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false);
        });
    },
    async getListMessagesBySite({ commit, getters }, payload) {
      let site = getters.currentUser.groups.find(
        (item) => item.ref_site == payload.site
      );
      let params = {
        site_id: [site.id],
        is_checkable: 1,
        order: false,
        sort: "message.updated_at",
      };
      await axios
        .get(apiUrl + "/api/common/v1/tracking-messages", { params: params })
        .then((response) => {
          if (response.data.dataRappels.length > 0) {
            let data = response.data.dataRappels.filter(
              (item) => item.folder.length != 0
            );
            let filteredData = data.filter((item) => item.is_read == false);
            if (data.length > 0) {
              commit("UPDATE_ACTIONS_LIST", data);
              commit("ALL_ACTIONS_LIST", data);
            }
            if (filteredData.length > 0) {
              commit("FILTERED_ACTIONS_LIST", filteredData);
            }
          } else {
            commit("RESET_ACTIONS_LIST");
          }
          axios.post(apiUrl + "/api/admin/v1/labels").then((response) => {
            if (response.data.data.length > 0) {
              commit("ALL_LABELS_LIST", response.data.data);
            }
          });
        })
        .catch(() => {
          commit("SET_LOADING", false);
        });
    },
    AppendMessageAction({ commit, dispatch, getters, state }, payload) {
      state.actionsList.unshift(payload.item);
      state.allActionsList.unshift(payload.item);
    },
    filterCheckedActionsList({ commit, dispatch, getters }, payload) {
      if (payload.event) {
        let data = [];
        if (getters.allActionsList.length > 0) {
          data = [...getters.allActionsList];
        }
        commit("UPDATE_ACTIONS_LIST", data);
      } else {
        let data = [];
        if (getters.filtedActionList.length > 0) {
          data = [...getters.filtedActionList];
        }
        commit("UPDATE_ACTIONS_LIST", data);
      }
    },
    checkItem({ commit, dispatch, getters }, payload) {
      commit("CHECK_ACTION", payload);
      // dispatch('filterCheckedActionsList',payload.checked)
    },
  },
};
