import { apiUrl, isAuthGuardActive } from '../../constants/config'
import axios from 'axios'

export default {
  state: {
      client:{},
      dateCle:[],
      documents:[],
      dossier:{},
      dossierStep:{},
      gestionFolder:[],
      dossierParentMessage: null,
      interlocutor:{},
      reponses:[],
      vehicule:[],
      messages:{
        answers:[],
        content:"",
        created_at:"",
        document:{},
        dossier:{
          user:{
            first_name:"",
            last_name:"",
            uuid:""
          }
        },
        id:null,
        respond_to:null,
        title:"",
        user:{
          first_name:"",
          last_name:"",
          uuid:""
        }
      },
      StateLoading: true,
      missingDocs : {},
      uploadedDocs : []  ,
      allFolders : [],
      allClients: [],
      getClients:null,
      dossierClient:{},
      loadingClients:false,
      client:[],
      clientsDocs:[]
  },
  getters: {
    client: state => state.client,
    dossierClient: state => state.dossierClient,
    dateCle: state => state.dateCle,
    documents: state => state.documents,
    dossier: state => state.dossier,
    dossierStep: state => state.dossierStep,
    gestionFolder: state => state.gestionFolder,
    reponses: state => state.reponses,
    vehicule: state => state.vehicule,     
    StateLoading: state => state.StateLoading,     
    messages: state => state.messages,     
    dossierParentMessage: state => state.dossierParentMessage ,
    missingDocs: state => state.missingDocs,
    uploadedDocs: state => state.uploadedDocs,
    interlocutor : state => state.interlocutor,
    allFolders : state => state.allFolders,
    allClients : state => state.allClients,
    getClients:state => state.getClients,
    currentPageFolders : state => state.currentPageFolders,
    currentPageClients : state => state.currentPageClients,
    loadingClients : state => state.loadingClients,
    clientsDocs : state => state.clientsDocs,
    loadingDocs: state => state.loadingDocs
  },
  mutations: {
    UPDATE_fOLDER(state,response){       
      state.client=response.client;
      state.dateCle=response.dateCle;
      state.documents=response.documents;
      state.dossier=response.dossier;
      state.dossierStep=response.dossier_step;
      state.gestionFolder=response.gestion
      state.reponses=response.reponses;
      state.vehicule=response.vehicule;   
      state.interlocutor = response.interlocutor ;
      response.dossier_parent_message ?  state.dossierParentMessage=response.dossier_parent_message.id : state.dossierParentMessage=null ;              
    },
    SET_LOADING(state, payload) {
      state.StateLoading = payload
    }, 
    GET_CLIENT(state,response){       
      state.dossierClient=response.data;           
    }, 
    SET_CLIENTS(state, payload){
      state.allClients = payload
    },
    RESET_CLIENTS(state, payload){
      state.allClients = []
    }, 
    UPDATE_MESSAGES(state,response){  
      state.messages=response;
    },
    UPDATE_PARENT(state,id){
      state.dossierParentMessage=id;
    },
    UPDATE_DOCS_MISSING(state, payload){
      state.missingDocs = payload ;
    },
    UPDATE_UPLOADED_DOCS(state, payload){
      state.uploadedDocs = payload 
    },
    UPDATE_DOSSIER_STATUS(state, payload){
      state.dossier.statut = payload ;
    },
    SET_FOLDERS(state, payload){
      state.allFolders = payload
    },
    RESET_MESSAGES(state){
      state.messages = {
        answers:[],
        content:"",
        created_at:"",
        document:{},
        dossier:{
          user:{
            first_name:"",
            last_name:"",
            uuid:""
          }
        },
        id:null,
        respond_to:null,
        title:"",
        user:{
          first_name:"",
          last_name:"",
          uuid:""
        }
      }
    },
    SET_CLIENTS_LAST_PAGE(state,payload){
      state.lastPageClients = payload
    },
    SET_CLIENTS_CURRENT_PAGE(state,payload){
      state.currentPageClients = payload
    },
    SET_LOADING_CLIENTS_DATA(state,payload){
      state.loadingClients = payload
    },
    SET_CLIENTS_DOCS(state,payload){
      state.clientsDocs = payload
    },
    SET_LOADING_CLIENTS_DOCS(state,payload){
      state.loadingDocs = payload
    },
  },
  actions: {
    getFolder(context, payload){
      context.commit('SET_LOADING', true);
      axios.get(apiUrl + '/api/admin/v1/folder/'+payload.num_dossier).then((response) => {           
      if (response.status === 200 || response.status === 201) {
        context.commit('UPDATE_fOLDER', response.data);
        if(response.data.dossier_parent_message){   
          console.log('has messages')               
          context.dispatch('updateMessages') ;
        }   
        else{
          console.log('no messages')  
          context.commit('RESET_MESSAGES')   
        }   
        context.dispatch('getMissingDocs') ;            
        }
      })
      .catch((error) => {
        console.log("error")
        context.commit('SET_LOADING', false);
      })      
    },
    getClientById(context, payload){
      context.commit('SET_LOADING', true);
      console.log()
      axios.get(apiUrl+'/api/admin/v1/folders/client/'+payload).then((response) => {           
      if (response.status === 200 || response.status === 201) {
        context.commit('GET_CLIENT', response.data);
       
      }})
      .catch((error) => {
        console.log("error")
        context.commit('SET_LOADING', false);
      })      
    },
    updateMessages(context){      
      axios.get(`${apiUrl}/api/common/v1/message/comments?message_id=${context.getters.dossierParentMessage}`)
      .then(({ data, status }) => {
        context.commit('UPDATE_MESSAGES', data.data);  
      },
      err => {
        console.log("error")                       
      })
    },
    addMessage(context,payload){
      let params ={
        config:{
          num_dossier : context.getters.dossierStep.dossier_id,
          title:"Dossier "+context.getters.dossier.numero,
          content:payload,
          email_copy:false,          
          parent_id:null
        }
      }
      if(context.getters.dossierParentMessage) {
        params.config.parent_id=context.getters.dossierParentMessage
      }
      else {
        params.config.users_uuids = [context.getters.dossier.user_id, context.getters.currentUser.id]
      }
      axios.post(`${apiUrl}/api/admin/v1/messages`, params.config)
      .then(function (response) {
        if(context.getters.dossierParentMessage==null) {
          context.commit('UPDATE_PARENT', response.data.data.id);   
        }
        context.dispatch('updateMessages')   ;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    getMissingDocs(context){
      let id_vialink=context.getters.currentUser.client.id_control_client_vialink ;      
      axios.get(apiUrl + '/api/admin/v1/vialink/list-docs/'+id_vialink).then((response) => {           
      if (response.status === 200 || response.status === 201) {   
        context.commit('UPDATE_DOCS_MISSING',response.data)   ;   
        context.commit('SET_LOADING', false); 
      }
      })
      .catch((error) => {
        console.log("error")
        context.commit('SET_LOADING', false);
      })
    },
    getAllFolders(context){
      context.commit('SET_LOADING', true);
      let config ={
        params:{
          limit : 10,
          page: 1
        }
      }
      axios.get(apiUrl + '/api/admin/v1/folders', config).then((response) => {           
        if (response.status === 200 || response.status === 201) {
          context.commit('SET_FOLDERS', response.data.data);                       
          context.commit('SET_LOADING', false);
        }
      })
      .catch((error) => {
        console.log("error")
        context.commit('SET_LOADING', false);
      })
    },
    getAllClients(context,payload){
    context.commit('SET_LOADING_CLIENTS_DATA', true);    
     return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/api/admin/v1/folders/clients', {params :payload}).then((response) => { 
      if (response.status == 200 || response.status ==201) {  
        resolve(response)
        context.commit('SET_CLIENTS', response.data.data);
        context.commit('SET_LOADING_CLIENTS_DATA', false);    
      }
    })
    .catch((error) => {
      context.commit('SET_LOADING_CLIENTS_DATA', false);    
      console.log("error",error)
      reject(error)
    })
		});
  
  }, 
  // GET FOLDERS LIST FOR CLIENT.
  getfoldersListByClientID(context, payload){
    context.commit('SET_LOADING_FOLDERS_LIST', true);
    axios.get(apiUrl+'/api/admin/v1/folders/client/'+payload).then((response) => {           
    if (response.status === 200 || response.status === 201) {
      context.commit('clien', response.data);
    }})
    .catch((error) => {
      console.log("error")
      context.commit('SET_LOADING_FOLDERS_LIST', false);
    })      
  },
  async getClientsDocs(context,payload){
    context.commit('SET_LOADING_CLIENTS_DOCS', true);    
   return new Promise((resolve, reject) => {
    axios.get(apiUrl + '/api/common/v1/documents/client/'+ payload).then((response) => { 
      if (response.status == 200 || response.status ==201) {  
        context.commit('SET_CLIENTS_DOCS', response.data.data);
        context.commit('SET_LOADING_CLIENTS_DOCS', false);   
       resolve(response) 
      }
    })
    .catch((error) => {
      context.commit('SET_LOADING_CLIENTS_DOCS', false);    
      console.log("error",error)
      reject(error)
    })
    });
  
  },

},     
}
