import axios from "axios";
import {apiUrl} from "@/constants/config";

export default {
    state: {
        loadingObjective: false,
        submitLoadingObjective: false,
        fullLoadingObjective: false,
        ObjectivesErrors: false,
        teams: [],
        functions: [],
        themes: [],
        ObjectivesUsers: [],
        ObjectivesThemeFunctions: [],
    },
    getters: {
        ObjectivesTeams: state => state.teams,
        ObjectivesFunctions: state => state.functions,
        ObjectivesThemes: state => state.themes,
        ObjectivesUsers: state => state.ObjectivesUsers,
        loadingObjective: state => state.loadingObjective,
        ObjectivesErrors: state => state.ObjectivesErrors,
        fullLoadingObjective: state => state.fullLoadingObjective,
        submitLoadingObjective: state => state.submitLoadingObjective,
        ObjectivesThemeFunctions: state => state.ObjectivesThemeFunctions,
    },
    mutations: {
        SET_INIT_OBJECTIVE(state, payload) {
            state.loadingObjective = false;
            state.submitLoadingObjective = false;
            state.fullLoadingObjective = false;
            state.ObjectivesErrors = false;
            state.teams = [];
            state.functions = [];
            state.themes = [];
            state.ObjectivesUsers =[];
        },
        SET_LOADING_OBJECTIVE(state, payload) {
            state.loadingObjective = payload
        },
        SET_TEAMS_OBJECTIVE_THEME_FUNCTIONS(state, payload) {
            state.ObjectivesThemeFunctions = payload
        },
        SET_SUBMIT_LOADING_OBJECTIVE(state, payload) {
            state.submitLoadingObjective = payload
        },
        SET_FULL_LOADING_OBJECTIVE(state, payload) {
            state.fullLoadingObjective = payload
        },
        SET_TEAMS_OBJECTIVE(state, payload) {
            state.teams = payload
        },
        SET_FUNCTION_OBJECTIVE(state, payload) {
            state.functions = payload
        },
        SET_THEMES_OBJECTIVE(state, payload) {
            state.themes = payload
        },
        SET_USERS_OBJECTIVE(state, payload) {
            state.ObjectivesUsers = payload
        },
        SET_OBJECTIVE_ERROR(state, payload) {
            state.ObjectivesErrors = payload
        },
    },
    actions: {
        initObjectivesData({commit}) {
            commit("SET_INIT_OBJECTIVE", []);
        },
        saveObjectives({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_SUBMIT_LOADING_OBJECTIVE", true);
                let url = apiUrl +
                    '/api/admin/v1/objectives/' + payload.siteRef +
                    '/team/' + payload.team +
                    '/function/' + payload.function +
                    '/theme/' + payload.theme +
                    '/kpi/' + payload.kpi +
                    '/year/' + payload.year;

                axios.post(url, payload.objectives).then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        resolve(true);
                        commit("SET_SUBMIT_LOADING_OBJECTIVE", false);
                    }
                }).catch(error => {
                    reject(error)
                    commit("SET_SUBMIT_LOADING_OBJECTIVE", false);
                })
            })
        },
        getObjectives({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_OBJECTIVE", true);
                commit("SET_USERS_OBJECTIVE", []);
                let url = apiUrl + '/api/admin/v1/objectives/' + payload.siteRef + '/team/' + payload.team;
                if (payload.function) {
                    url += '/function/' + payload.function;
                }if (payload.theme && payload.kpi && payload.year) {
                    url += '/theme/' + payload.theme + '/kpi/' + payload.kpi + '/year/' + payload.year;
                }
                axios.get(url).then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        resolve(true)
                        commit("SET_LOADING_OBJECTIVE", false)
                        commit("SET_USERS_OBJECTIVE", res.data);
                    }
                }).catch(error => {
                    commit("SET_LOADING_OBJECTIVE", false)
                    commit("SET_USERS_OBJECTIVE", []);
                    commit("SET_OBJECTIVE_ERROR", true)
                    reject(error)
                })
            })
        },
        getListTeamAndFunctionBySiteId({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_OBJECTIVE", true);
                axios.get(apiUrl + '/api/admin/v1/objectives/' + payload.siteRef + '/data-filter').then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        resolve(true)
                        commit("SET_LOADING_OBJECTIVE", false)
                        commit("SET_TEAMS_OBJECTIVE", res.data.teams);
                        commit("SET_FUNCTION_OBJECTIVE", res.data.functions);
                    }
                }).catch(error => {
                    commit("SET_LOADING_OBJECTIVE", false)
                    commit("SET_TEAMS_OBJECTIVE", []);
                    commit("SET_FUNCTION_OBJECTIVE", []);
                    commit("SET_OBJECTIVE_ERROR", true)
                    reject(error)
                })
            })
        },
        getThemeFunctions({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_OBJECTIVE", true);
                axios.get(apiUrl + '/api/admin/v1/theme/functions').then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        resolve(true)
                        commit("SET_LOADING_OBJECTIVE", false)
                        commit("SET_TEAMS_OBJECTIVE_THEME_FUNCTIONS", res.data);
                    }
                }).catch(error => {
                    commit("SET_LOADING_OBJECTIVE", false)
                    commit("SET_OBJECTIVE_ERROR", true)
                    commit("SET_TEAMS_OBJECTIVE_THEME_FUNCTIONS", []);
                    reject(error)
                })
            })
        },
        getThemeByFunctionId({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_THEMES_OBJECTIVE", []);
                commit("SET_FULL_LOADING_OBJECTIVE", true);
                axios.get(apiUrl + '/api/admin/v1/objectives/theme/function/' + payload.function).then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        resolve(true)
                        commit("SET_FULL_LOADING_OBJECTIVE", false)
                        commit("SET_THEMES_OBJECTIVE", res.data);
                    }
                }).catch(error => {
                    commit("SET_FULL_LOADING_OBJECTIVE", false)
                    commit("SET_THEMES_OBJECTIVE", []);
                    commit("SET_OBJECTIVE_ERROR", true)
                    reject(error)
                })
            })
        },
    }
}
