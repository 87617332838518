import { setCurrentUser, getCurrentUser,checkCDVRole, checkSecretaireRole } from '.';
import i18n from "../locales/index";
import store from '../store/index'

export default (to, from, next) => {
    const isSecreatire = checkSecretaireRole()
    const isCDV = checkCDVRole()
    const user = getCurrentUser();
    const ref_site = user!=null?user.groups.find(site => site.ref_site!=null && site.typeGroup =="Site"):'';
    const  authRoute = ['login']    

    let language= to?.params?.lang ? ( (localStorage.getItem('lang') != to.params.lang) ? to.params.lang : localStorage.getItem('lang') ) : 'fr';
    // set the current language for i18n.
    i18n.locale = language;
    if (to.matched.some(record => record.meta.loginRequired)) {
        if (user) {
          return  next();
        } 
        else{
            setCurrentUser(null);   
            next({ name: 'login' })
        }
    } 
    else {
        if(user && authRoute.some (item => to.name == item)){      
            if(isSecreatire ||isCDV){
           next({name: 'dashboardSecretaire', params:{ref_site:ref_site.ref_site, lang: i18n.locale}}) ;
            }
            else{
                next({name: 'dashboardSecretaire', params:{ref_site:ref_site.ref_site, lang: i18n.locale}}) ;
            }
        }
        else{
          return  next();
        }    
    }
    // traitement des pages inaccessibles ici 
    if (to.meta.roles) {
        if (!user) return false;
        const roleUser = user.fonction;
        if (to.meta.roles.includes(roleUser.id)) {
            next()
        } else {

            next({ name: 'error-403', params:{ lang: i18n.locale } });
        }
    }
    if (to.name == 'dashboardSecretaire') {
        if(isSecreatire || isCDV){
            next({name: 'dashboardSecretaire', params:{ref_site:ref_site.ref_site, lang: i18n.locale}}) ;
        }
        else {
           return next() ;
        }
    }
   
}