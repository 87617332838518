import axios from "axios";
import {apiUrl} from "@/constants/config";
const { CancelToken } = axios;
let animationRhCancel;
export default {
    state: {
        loadingAnimationSeller: false,
        loadingAnimationFollow: false,
        loadingFicheAnimationByUser: false,
        rhAnimation: null,
        animationsbyUser: [],
        animationQuestions: [],
        animationQuestionsCP: [],
        usersBYFunction: [],
        ficheAnimationByUser: [],
        loadingRhAnimationList: false,
        rhAnimationList: [],
        rhAnimationMeta:  {
            current_page: 0,
            from: 0,
            last_page: 0,
            per_page: 0,
            to: 0,
            total:0
        },
    },
    getters: {
        loadingAnimation: state => state.loadingAnimation,
        animations: state => state.animations,
        animationsUsers: state => state.animationsUsers,
        animationQuestions: state => state.animationQuestions,
        animationsbyUser: state => state.animationsbyUser,
        loadingFicheAnimationByUser: state => state.loadingFicheAnimationByUser,
        ficheAnimationByUser: state => state.ficheAnimationByUser,
        rhAnimation: state => state.rhAnimation,
        loadingRhAnimationList: state => state.loadingRhAnimationList,
        rhAnimationList: state => state.rhAnimationList,
        rhAnimationMeta: state => state.rhAnimationMeta,
        loadingAnimationFollow: state => state.loadingAnimationFollow,
        loadingAnimationSeller: state => state.loadingAnimationSeller,
    },
    mutations: {
        SET_INIT_ANIMATIONS(state, payload) {
            state.loadingAnimationSeller = false;
            state.loadingAnimationFollow = false;
            state.animationsbyUser = [];
            state.usersBYFunction = [];
            state.animationQuestions = [];
            state.ficheAnimationByUser = [];
        },
        SET_ANIMATIONS_BY_USER(state, payload) {
            state.animationsbyUser = payload;
        },
        SET_USERS_BY_FUNCTION(state, payload) {
            state.usersBYFunction = payload;
        },
        SET_LOADING_ANIMATIONS_SELLER(state, payload) {
            state.loadingAnimationSeller = payload;
        },
        SET_LOADING_ANIMATIONS_FOLLOW(state, payload) {
            state.loadingAnimationFollow = payload;
        },
        SET_LOADING_FICHE_ANIMATIONS_BY_USER(state, payload) {
            state.loadingFicheAnimationByUser = payload;
        },
        SET_FICHE_ANIMATIONS_BY_USER(state, payload) {
            state.ficheAnimationByUser = payload;
        },
        SET_ANIMATIONS_QUESTION(state, payload) {
            state.animationQuestionsCP =  JSON.parse(JSON.stringify(payload));
            state.animationQuestions = payload;
        },
        SET_RH_ANIMATIONS(state, payload) {
            state.rhAnimation = payload
        },
        SET_ANIMATIONS_QUESTIONS_RESPONSE(state, payload) {
            state.animationQuestions = [];
            state.animationQuestions =  JSON.parse(JSON.stringify(state.animationQuestionsCP));
            if (payload.length>0){
                state.animationQuestions.map((question) => {
                    let reponse = payload.find((rp) => rp.question_id == question.question_id);
                    if (reponse) {
                        question.response = reponse.reponse;
                        question.copyresponse = reponse.reponse;
                    }
                    return question;
                })
            }

        },
        RESET_ANIMATION_GESTIONS_RESPONSE(state, payload) {
            state.animationQuestions =  JSON.parse(JSON.stringify(state.animationQuestionsCP));
        },
        SET_RH_ANIMATIONS_LIST(state, payload) {
            state.rhAnimationList =  payload;
        },
        SET_RH_ANIMATIONS_META(state, payload) {
            state.rhAnimationMeta =  payload;
        },
        SET_LOADING_RH_ANIMATIONS_LIST(state, payload) {
            state.loadingRhAnimationList =  payload;
        }
    },
    actions: {
        resetQuestionResponse({commit}) {
            commit("RESET_ANIMATION_GESTIONS_RESPONSE", []);
        },
        initAnimationsData({commit}) {
            commit("SET_INIT_ANIMATIONS", []);
        },
        initAnimationsByUserData({commit}) {
            commit("SET_ANIMATIONS_BY_USER", []);
        },
        initFicheAnimationsByUser({commit}) {
            commit("SET_FICHE_ANIMATIONS_BY_USER", []);
        },
        getQuestionsResponseAnimation({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.get(apiUrl + `/api/admin/v1/animations/questions/user/${payload.userId}/year/${payload.year}/month/${payload.month}/reponse`, {
                    params: payload
                }).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_ANIMATIONS_QUESTIONS_RESPONSE", response.data.data);
                        commit("SET_RH_ANIMATIONS", response.data.rh);
                    }
                    resolve(true)
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        saveQuestionsAnimation({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(apiUrl + `/api/admin/v1/animations/questions/user/${payload.userId}/year/${payload.year}/month/${payload.month}/reponse`, payload).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_ANIMATIONS_QUESTIONS_RESPONSE", response.data.data);
                        commit("SET_RH_ANIMATIONS", response.data.rh);
                    }
                    resolve(true)
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        getQuestionAnimation({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.get(apiUrl + `/api/admin/v1/animations/questions`, {
                    params: payload
                }).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_ANIMATIONS_QUESTION", response.data.data.map((item)=> {
                            return {
                                ...item,
                                copyresponse: item.reponse,
                                isModeEdit: false,
                                isloading: false,
                            }
                        }));
                    }
                    resolve(true)
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        getFicheAnimationBYUser({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_FICHE_ANIMATIONS_BY_USER", true);
                commit("SET_FICHE_ANIMATIONS_BY_USER", []);
                axios.get(apiUrl + `/api/admin/v1/users/${payload.userId}/fiche/animation`, {
                    params: payload
                }).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_LOADING_FICHE_ANIMATIONS_BY_USER", false);
                        commit("SET_FICHE_ANIMATIONS_BY_USER", response.data);
                    }
                    resolve(true)
                }).catch((error) => {
                    commit("SET_LOADING_FICHE_ANIMATIONS_BY_USER", false);
                    reject(error);
                })
            });
        },
        getAnimationsbyUser({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_ANIMATIONS_SELLER", true);

                axios.get(apiUrl + `/api/admin/v1/animations/user/${payload.userId}/year/${payload.year}/month/${payload.month}`).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_LOADING_ANIMATIONS_SELLER", false);
                        commit("SET_ANIMATIONS_BY_USER",response.data.map((item) => {
                            return {
                                ...item,
                                toBeDeleted: false
                            }
                        }));
                    }
                    resolve(true)
                }).catch((error) => {
                    commit('SET_LOADING_ANIMATIONS_SELLER', false);
                    console.log(error)
                    reject(error);
                })
            });
        },
        saveAnimationsbyUser({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_ANIMATIONS_FOLLOW", true);
                axios.post(apiUrl + `/api/admin/v1/animations/user/${payload.userId}/year/${payload.year}/month/${payload.month}`, payload).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_LOADING_ANIMATIONS_SELLER", false);
                        commit("SET_ANIMATIONS_BY_USER",response.data.map((item) => {
                            return {
                                ...item,
                                toBeDeleted: false
                            }
                        }));
                    }
                    commit("SET_LOADING_ANIMATIONS_FOLLOW", false);
                    resolve(true)
                }).catch((error) => {
                    context.commit('SET_LOADING_ANIMATIONS_SELLER', false);
                    console.log(error)
                    reject(error);
                })
            });
        },
        estimateByUserAndDate({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_ANIMATIONS_FOLLOW", true);
                axios.get(apiUrl + `/api/admin/v1/animations/questions/user/${payload.userId}/year/${payload.year}/month/${payload.month}/estimate`, {
                    params: payload
                }).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_ANIMATIONS_QUESTIONS_RESPONSE", response.data.data);
                        commit("SET_RH_ANIMATIONS", response.data.rh);
                    }
                    resolve(true);
                    commit("SET_LOADING_ANIMATIONS_FOLLOW", false);
                }).catch((error) => {
                    commit("SET_LOADING_ANIMATIONS_FOLLOW", false);
                    console.log(error)
                    reject(error);
                })
            })
        },
        confirmByUserAndDate({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit("SET_LOADING_ANIMATIONS_FOLLOW", true);
                axios.get(apiUrl + `/api/admin/v1/animations/questions/user/${payload.userId}/year/${payload.year}/month/${payload.month}/confirm`, {
                    params: payload
                }).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_ANIMATIONS_QUESTIONS_RESPONSE", response.data.data);
                        commit("SET_RH_ANIMATIONS", response.data.rh);
                        commit("SET_LOADING_ANIMATIONS_FOLLOW", false);
                    }
                    resolve(true)
                }).catch((error) => {
                    commit("SET_LOADING_ANIMATIONS_FOLLOW", false);
                    console.log(error)
                    reject(error);
                })
            })
        },
        findAnimationRhByRefSite({commit}, payload) {
            return new Promise((resolve, reject) => {
                if (animationRhCancel) {
                    animationRhCancel("La requête précédente a été annulée.");
                }
                const source = CancelToken.source();
                animationRhCancel = source.cancel;
                commit("SET_LOADING_RH_ANIMATIONS_LIST", true);
                axios.get(apiUrl + `/api/admin/v1/animations/site/${payload.refSite}/rh`, {
                    cancelToken: source.token,
                    params: payload
                }).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit("SET_RH_ANIMATIONS_LIST", response.data.data);
                        commit("SET_RH_ANIMATIONS_META", response.data.meta);
                    }
                    commit("SET_LOADING_RH_ANIMATIONS_LIST", false);
                    resolve(true)
                }).catch((error) => {
                    commit("SET_LOADING_RH_ANIMATIONS_LIST", false);
                    console.log(error)
                    reject(error);
                })
            })
        },
        deleteAnimationRh({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.delete(apiUrl + `/api/admin/v1/animations/rh/${payload.id}`, {
                    params: payload
                }).then((response) => {
                    resolve(true)
                }).catch((error) => {
                    console.log(error)
                    reject(error);
                })
            })
        }
    }
}
