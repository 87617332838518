import { apiUrl} from '../../constants/config'
import axios from 'axios'

export default {
  state: {
      settings:[],
      loadSettings:false,
      succesMajSettings:false,
      succesDeleteSettings:false,
      loadingMAJSettings:false,
      successAddSetting: false,
      successSaveConfigSite:false,
      planningsList:[],
      loadingPlanningsList:false,
      successAddPlanning : false,
      succesDeletePlanning: false,
      loadingAddPlannings:false
  },
  getters: {
    settings: state => state.settings,
    loadSettings: state => state.loadSettings,
    succesMajSettings: state => state.succesMajSettings,
    succesDeleteSettings : state => state.succesDeleteSettings,
    loadingMAJSettings : state => state.loadingMAJSettings,
    successAddSetting : state => state.successAddSetting,
    successSaveConfigSite : state => state.successSaveConfigSite,
    planningsList : state =>state.planningsList,
    loadingPlanningsList : state => state.loadingPlanningsList,
    successAddPlanning : state => state.successAddPlanning,
    succesDeletePlanning : state => state.succesDeletePlanning,
    loadingAddPlannings : state => state.loadingAddPlannings
  },
  mutations: {
    SET_SETTINGS(state, payload){
      state.settings = payload
    },
    SET_LOADING_SETTINGS(state, payload){
      state.loadSettings = payload
    },
    SET_SUCCESS_MAJ_SETTINGS(state, payload){
      state.succesMajSettings = payload
    },
    SET_SUCCESS_DELETE_SETTINGS(state, payload){
      state.succesDeleteSettings = payload
    },
    SET_LOADING_MAJ_SETTINGS(state, payload){
      state.loadingMAJSettings = payload
    },
    SET_SUCCESS_ADD_SETTINGS(state, payload){
      state.successAddSetting = payload
    },
    SET_SUCCESS_CONFIG_SITE(state, payload){
      state.successSaveConfigSite = payload
    },
    SET_PLANNINGS_LIST(state, payload){
      state.planningsList = payload
    },
    SET_LOADING_PLANNINGS(state, payload){
      state.loadingPlanningsList = payload
    },
    SET_SUCCESS_ADD_PLANNING(state, payload){
      state.successAddPlanning = payload
    },
    SET_LOADING_ADD_PLANNINGS(state, payload){
      state.loadingAddPlannings = payload
    },
    SET_SUCCESS_DELETE_PLANNING(state, payload){
      state.succesDeletePlanning = payload
    },
  },
  actions: {
    getDashboradSettings({commit}, payload){
      commit('SET_SUCCESS_CONFIG_SITE', false); 
      axios.get(apiUrl + '/api/common/v1/setting/'+payload).then((response) => {           
      if (response.status === 200 || response.status === 201) {
        localStorage.setItem('settings', JSON.stringify(response.data))  
        commit('SET_SUCCESS_CONFIG_SITE', true); 
        }
      })
      .catch((error) => {
        console.log("error");
        commit('SET_SUCCESS_CONFIG_SITE', false); 
      })      
    },
   // GESTION DES PRESTATIONS
    getPrestationsList({commit}, payload){
    commit('SET_LOADING_SETTINGS',true)
      axios.get(apiUrl + '/api/admin/v1/prestations', { params: payload }).then((response) => {           
        if (response.status === 200 || response.status === 201) {
          commit('SET_LOADING_SETTINGS',false);
          commit('SET_SETTINGS',response.data.prestations)
          }
        })
        .catch((error) => {
          commit('SET_LOADING_SETTINGS',false);
          console.log("error", error)
        }) 
   
    },
    setPrestations({commit}, payload){
    commit('SET_LOADING_MAJ_SETTINGS', true);
    axios.put(apiUrl + '/api/admin/v1/prestations', payload ).then((response) => {           
      if (response.status === 200 || response.status === 201) { 
        commit('SET_SUCCESS_MAJ_SETTINGS', true);
        }
      })
      .catch((error) => {
        commit('SET_SUCCESS_MAJ_SETTINGS', false);
        console.log("error", error)
      }).finally((res)=>{
        commit('SET_LOADING_MAJ_SETTINGS', false);
      })
 
    },
    removePrestation({commit}, payload){
      axios.delete(apiUrl + '/api/admin/v1/prestations/'+ payload ).then((response) => {           
    if (response.status === 200 || response.status === 201) { 
      commit('SET_SUCCESS_DELETE_SETTINGS', true);
      
      }
    })
    .catch((error) => {
      commit('SET_SUCCESS_DELETE_SETTINGS', false);
      console.log("error", error)
    }) 

    },
    addPrestation({commit}, payload){
     axios.put(apiUrl + '/api/admin/v1/prestations', payload ).then((response) => {           
     if (response.status === 200 || response.status === 201) { 
      commit('SET_SUCCESS_ADD_SETTINGS', true);
      }
    })
    .catch((error) => {
     commit('SET_SUCCESS_ADD_SETTINGS', false);
      console.log("error", error)
    }) 
   },
   // GESTION DES PLANNINGS
   getPlanningsList({commit}, payload){
    commit('SET_LOADING_PLANNINGS',true)
    axios.get(apiUrl + '/api/admin/v1/planning/'+payload ).then((response) => {           
      if (response.status === 200 || response.status === 201) {
        commit('SET_LOADING_PLANNINGS',false);
        commit('SET_PLANNINGS_LIST',response.data)
        }
      })
      .catch((error) => {
        commit('SET_LOADING_PLANNINGS',false);
        console.log("error", error)
      }) 
  },
  addPlanning({commit}, payload){
    commit('SET_LOADING_ADD_PLANNINGS', true);
    axios.post(apiUrl + '/api/admin/v1/planning',  payload ).then((response) => {           
      if (response.status === 200 || response.status === 201) { 
       commit('SET_SUCCESS_ADD_PLANNING', true);
       commit('SET_LOADING_ADD_PLANNINGS', false);
       }
     })
     .catch((error) => {
      commit('SET_SUCCESS_ADD_PLANNING', false);
      commit('SET_LOADING_ADD_PLANNINGS', false);
       console.log("error", error)
     }) 
  },
  removePlaning({commit}, payload){
    axios.delete(apiUrl + '/api/admin/v1/planning/'+ payload ).then((response) => {           
        if (response.status === 200 || response.status === 201) { 
          commit('SET_SUCCESS_DELETE_PLANNING', true);
          }
    })
    .catch((error) => {
      commit('SET_SUCCESS_DELETE_PLANNING', false);
      console.log("error", error)
    }) 

  },
  },
}