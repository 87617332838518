import { apiUrl, isAuthGuardActive } from '../../constants/config';
import { setCurrentUser, getCurrentUser } from '../../utils'
import axios from "axios";
import router from '../../router';
 import messaging from '../../main';
import {getAuth, signInAnonymously} from 'firebase/auth';
import { getToken } from "firebase/messaging";

export default {
    state: {
        currentUser: isAuthGuardActive ? getCurrentUser() : currentUser,
        loginError: null,
        processing: false,
        forgotMailSuccess: null,
        resetPasswordSuccess: null,
        itemsuser: null,
        process: true,
        isClient: null,
        uid: null,
        phone: null,
        notificationsCount: {},
        remindersCount: {},
        allNotifications: {},
        allReminders :{},
        userFcm: null,
        loggedOut:false
    },
    getters: {
        currentUser: state => state.currentUser,
        processing: state => state.processing,
        loginError: state => state.loginError,
        forgotMailSuccess: state => state.forgotMailSuccess,
        resetPasswordSuccess: state => state.resetPasswordSuccess,
        itemsuser: state => state.itemsuser,
        process: state => state.process,
        isClient : state => state.isClient,
        uid: state => state.uid,
        phone: state=> state.phone,
        allNotifications : state => state.allNotifications,
        notificationsCount: state => state.notificationsCount,
        remindersCount: state => state.remindersCount,
        userFcm: state => state.userFcm,
        loggedOut: state =>state.loggedOut,
        allReminders : state => state.allReminders
    },
    mutations: {
        getuserprofil(state, items) {
            state.itemsuser = items
        },
        seteditprofil(state, payload) {
            state.process = payload
        },
        setUser(state, payload) {
            state.currentUser = payload
            state.processing = false
            state.loginError = null
        },
        setLogout(state) {
            state.currentUser = null
            state.processing = false
            state.loginError = null
            state.userFcm =null
        },
        setLoggedOut(state,payload) {
            state.loggedOut = payload
        },
        setProcessing(state, payload) {
            state.processing = payload
            state.loginError = null
        },
        setError(state, payload) {
            state.loginError = payload
            state.currentUser = null
            state.processing = false
        },
        setForgotMailSuccess(state) {
            state.loginError = null
            state.currentUser = null
            state.processing = false
            state.forgotMailSuccess = true
        },
        setResetPasswordSuccess(state) {
            state.loginError = null
            state.currentUser = null
            state.processing = false
            state.resetPasswordSuccess = true
        },
        clearError(state) {
            state.loginError = null
        },
        setUid(state, payload) {
            state.uid = payload
        },
        setPhone(state, payload) {
            state.phone = payload
        },
        setNotifications(state, payload){
            state.allNotifications = {
                ...state.allNotifications,
                ...payload
            }
        },
        setReminders(state, payload){
            state.allReminders = {
                ...state.allReminders,
                ...payload
            }
        },
        setCountNofifications(state, payload){
            state.notificationsCount = {
                ...state.notificationsCount,
                ...payload
            }
        },
        setCountReminders(state, payload){
            state.remindersCount = {
                ...state.remindersCount,
                ...payload
            }
        },
        setFcm(state,payload){
            state.userFcm=payload
        }
    },
    actions: {
        login({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            return new Promise((resolve, reject) => {
                axios.post(`${apiUrl}/api/common/v1/auth/login`, payload)
                    .then(({ data, status }) => {
                            if (status === 200) {
                                resolve(data)
                            }
                            if((data?.data?.profil!=null&& parseInt(data.data.profil.id) === 1) || data.client === true){
                                   commit('setError', "Les identifiants ne correspondent pas")
                              }else {
                                 if(data.data.groups.length>0){
                                    const item = data.data;
                                    if(data.data.first_connexion ==0){
                                        setCurrentUser(item)
                                        commit('setUser', item)
                                        localStorage.setItem("function", JSON.stringify(item.fonction))
                                        localStorage.setItem("profil", JSON.stringify(item.profil))
                                    }

                                  }else{
                                      setCurrentUser(null);
                                      commit('setError', "compte n'appartient à aucun site")
                                  }
                             }

                        },
                        err => {
                            setCurrentUser(null);
                            commit('setError', err.response.data.message)
                            setTimeout(() => {
                                commit('clearError')
                            }, 3000)
                        })
                    .catch(error => {
                        // reject(error);
                    })
            })
        },
        loginClient({ commit }, payload) {
            commit('clearError')
            return new Promise((resolve, reject) => {
                axios.post(`${apiUrl}/api/common/v1/auth/login`, payload)
                    .then(({ data, status }) => {
                            if (status === 200) {
                                resolve(true)
                            }
                            const item = data.data;
                            setCurrentUser(item)
                            commit('setUser', item)
                            localStorage.setItem("function", JSON.stringify(item.fonction));

                        },
                        err => {
                            setCurrentUser(null);
                            commit('setError', err.response.data.message)
                            setTimeout(() => {
                                commit('clearError')
                            }, 3000)
                        })
                    .catch(error => {
                        // reject(error);
                    })
            })
        },
        firstLoginClient({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            commit('setPhone', payload.phone)
            commit('setUid', payload.uuid)
            return new Promise((resolve, reject) => {
                axios.post(`${apiUrl}/api/common/v1/auth/first/login`, payload)
                    .then(({ data, status }) => {
                            if (status === 200) {
                                resolve(true)
                            }
                            // router.push({name:"setPasswordClient"})

                        },
                        err => {
                            // setCurrentUser(null);
                            commit('setError', err.response.data.message)
                            setTimeout(() => {
                                commit('clearError')
                            }, 3000)
                        })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        signOut({dispatch,commit}){
            axios.post(`${apiUrl}/api/common/v1/auth/logout`)
            .then(
                res => {
                    if(res.status==200){
                        commit('setLoggedOut',true);
                        dispatch('logout')
                    }

                },
                err => {
                    console.log(err)
                }
            )
            .catch(error => {
                console.log(error)
            })
        },
        logout({ commit, state }) {
            commit('setLogout');

            localStorage.removeItem("profil");
            localStorage.removeItem("FolderList");
            localStorage.removeItem("brand_name");
            localStorage.removeItem("function");
            localStorage.removeItem("lang");
            localStorage.removeItem("last_visited_step");
            localStorage.removeItem("user");
            localStorage.removeItem("settings");
          //  localStorage.clear();
           // router.push({name:'login'})


        },
        forgotPassword({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            axios.post(`${apiUrl}/api/common/v1/send-reset-link-password`, payload)
                .then(
                    res => {
                        commit('clearError')
                        commit('setForgotMailSuccess', 'Email envoyé avec succès !')
                    },
                    err => {
                        commit('setError', err.response.data.message)
                        setTimeout(() => {
                            commit('clearError')
                        }, 3000)
                    }

                )
        },
        // verify token
        verifyToken({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            axios.post(`${apiUrl}/api/common/v1/verify-validity-token`, payload)
                .then(
                    res => {},
                    err => {
                        commit('setError', err.response.data)
                        setTimeout(() => {
                            commit('clearError')
                        }, 3000)
                    }

                )
        },
        resetPassword({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            axios.post(`${apiUrl}/api/common/v1/password/reset`, payload)
                .then(
                    res => {
                        commit('clearError')
                        commit('setResetPasswordSuccess', res.data.message)
                    },
                    err => {
                        commit('setError', err.message)
                        setTimeout(() => {
                            commit('clearError')
                        }, 3000)
                    }

                )
        },
        setPasswordClient({ commit }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            axios.post(`${apiUrl}/api/common/v1/auth/first/login`, payload)
            .then(
                res => {
                    const item = res.data.data;
                    setCurrentUser(item)
                    commit('setUser', item)
                    localStorage.setItem("function", JSON.stringify(item.fonction));
                },
                err => {
                    commit('setError', err.message)
                    setTimeout(() => {
                        commit('clearError')
                    }, 3000)
                }
            )
        },
        getUser({ commit }, payload) {
            return new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/admin/v1/users/${payload.uuid}`)
                .then(({ data, status }) => {
                        commit('getuserprofil', data)
                        resolve(data);
                    },
                    err => {
                        reject(err);
                        commit('setError', err.message)
                        setTimeout(() => {
                            commit('clearError')
                        }, 3000)
                    })
            })
        },
        updateUser({ commit }, payload) {
            return new Promise((resolve, reject) => {
            axios.put(`${apiUrl}/api/common/v1/users/${payload.uuid}`, payload)
                .then(({ data, status }) => {
                    if (status === 200 || status === 204) {
                        commit('seteditprofil', true);
                        setCurrentUser(data.data)
                        commit('setUser', data.data);
                    } else if (status === 422) {
                        commit('seteditprofil', false);
                    }
                    resolve(data);
                }, )
                .catch(error => {
                    commit('setError', error.message)
                    commit('seteditprofil', false)
                    setTimeout(() => {
                        commit('seteditprofil', false)
                    }, 3000)
                    reject(error);
                })
            })
        },
        getNotifications({ commit }, payload){
            let params = {
                is_global:true,
                notification:true,
                order: false,
                sort:'message.updated_at',
                numCmd: payload || null
            }
            return new Promise((resolve, reject) => {
                axios.get(`${apiUrl}/api/common/v1/tracking-messages`, {params : params})
                    .then((res)=>{
                        commit('setReminders', {
                            [payload ? payload : 'default']: res.data.dataRappels
                        })
                        commit('setCountReminders', {
                            [payload ? payload : 'default']: res.data.indicatorsRappels.totalUnReadedMessages
                        })
                        commit('setNotifications',{
                            [payload ? payload : 'default']: res.data.data
                        } )
                        commit('setCountNofifications',{
                            [payload ? payload : 'default']: res.data.indicators.totalUnReadedMessages
                        })
                        resolve(res.data);
                    })
                    .catch((err)=>{
                        reject(err);
                    })


            });

        },

        viewItem({ commit, dispatch }, payload){
            let params = {
                status: payload.status
            }
            axios.put(`${apiUrl}/api/common/v1/update/message/status/${payload.message_id}`, params)
            .then((res)=>{
                // commit('setNotifications', res.data.data)
                // commit('setCountNofifications', res.data.indicators.totalUnReadedMessages)
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        viewCheckItem({ commit, dispatch }, payload){
            let params = {
                status: payload.status
            }
            axios.put(`${apiUrl}/api/common/v1/update/message/check/${payload.message_id}`, params)
            .then((res)=>{
                // commit('setNotifications', res.data.data)
                // commit('setCountNofifications', res.data.indicators.totalUnReadedMessages)
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        async seeAll({ commit, dispatch }){
            await axios.put(`${apiUrl}/api/common/v1/update/unread/message`)
            .then((res)=>{
                console.log(res,"res")
            })
            .catch((err)=>{
                console.log(err)
            })

        },
        sendMessageFirebase({ commit, dispatch }){
            let headers= {
                "Content-Type": "application/json",
                "Authorization": "key=AAAAOyXuAkE:APA91bFXsGoSbg8t3sOPlbCHaX0GnJYm8XHEf7-98YdKq7XQ9ZzkYyO4TVwgRL3756Rk5Yn_U7IwmokfXa2LKJOVZeajamiu8TR_Xgl8GvI-5VMsCN0eJu1SskBYuVe53QNq7FALEHtp"
            }
            let payload= {
                "to":"d7XuF5rl0us7T7GJwGb8em:APA91bETAzhvcdjkRM_W3x0FJBKQMuBRplVvk18w996fXCGP3Bhrj9RXCCln23dh9tv9VGc0fU2h2Vb82YwrqEtM1YsHKeBsZnMFPe3Zt_CIW-YC3kmxk4GqBg4f-mkw5W0KhOdcZwbu",
                "data" : {
                    "username" : "anees.baig.barlas",
                    "message": "This is a test message"
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`https://fcm.googleapis.com/fcm/send`,payload, { headers })
                    .then(({ data, status }) => {
                            if (status === 200) {
                                resolve(true)
                            }
                        },
                        err => {
                            reject(err);
                        })
                    .catch(error => {
                        reject(error);
                    })
            })

        },
        async getFcmFirebase({ commit, dispatch }){
            // await signInAnonymously(getAuth())
            if(messaging)
            return getToken(messaging,{vapidKey:'BJPXjn9S_zqHdnGSY-rcIICa8iOksKIG49usB2yxv3NW0UWW4xDTVbDvC4j10LMuYXpr6MYim1l61I9YJptpm_Q'})
            else
            return null
        },
    }
}
