
export default [
{
    path: '/:lang/dashboard-secretaire/:ref_site',
    name: 'dashboardSecretaire',
    component: () => import('@/pages/dashboard/Index'),
    meta: {
      breadcrumb: [],
      roles: [3,2],
    },
  },
  {
    path: '/:lang/edit-profile',
    name: 'editProfile',
    component: () => import('@/pages/user/EditProfil'),
    meta: {
      breadcrumb: [
        { text: 'Dashboard', href: '/' },
        { text: 'Editer mon profil' },
      ],
    },
  },
  // CLIENTS 
  {
    path: '/:lang/clients',
    name: 'listClient',
    component: () => import('@/pages/client/List'),
    meta: {
      breadcrumb: [
        { text: 'Dashboard', href: '/' },
        { text: 'Liste des clients', href: '/clients' },   
      ]
    },
  },
  {
    path: '/:lang/show/:id',
    name:'detailClientVue',
    component: () => import('@/pages/client/details/Index'),
    meta: {
      breadcrumb: [
        { text: 'Dashboard', href: '/' },
        { text: 'Liste des clients', href: '/clients' },   
        { text: 'Détails clients', href: '/clients/show/:id' },      
      ]
    },
  },
]