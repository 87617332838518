import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import user from './modules/user'
import commande from './modules/commande'
import folderClient from './modules/folderClient'
import planningSecretaire from './modules/planningSecretaire'
import folderManagement from './modules/folderManagement'
import secretaryFolderManagement from './modules/secretaryFolderManagement'
import  settings from './modules/settings'
import  kpi from './modules/kpi'
import  objective from './modules/objective'
import  Aides from './modules/aides'
import  Animation from './modules/animation'
import  Documents from './modules/documents'
import  Batch from './modules/batch'
export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        commande,
        folderClient,
        planningSecretaire,
        folderManagement,
        secretaryFolderManagement,
        settings,
        kpi,
        objective,
        Aides,
        Animation,
        Documents,
        Batch
    }
})
