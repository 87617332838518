import Vue from 'vue';
import VueRouter from 'vue-router';
import authRoutes from './AuthRoutes';
import mainRoutes from './MainRoutes';
import errorsRoutes from './ErrorsRoutes';
import layout from '../layout/index';
import UserLayout from '../layout/UserLayout';
import IframeLayout from "../layout/IframeLayout";
import AuthGuard from '../utils/auth.guard';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'base',
    redirect: { name: 'login' },
    component: () => import('@/layout/UserLayout'),
    children: [...authRoutes],
  },

  /** new iframe layout **/
  {
    path: '/aides/show/:num_cmd',
    name: 'iframeInterne',
    component: IframeLayout,
    component: () => import('@/pages/InputForm'),
  },
  /**  aids management**/
  {
    path: '/gestion/aides/:num_cmd',
    component: IframeLayout,
    children: [
      {
        path: '',
        name: 'aidsManagement',
        component: () => import('@/pages/independentPages/AidsManagement/Default.vue'),
      },
      {
        path: 'stellantis',
        name: 'aidsManagement_stellantis',
        component: () => import('@/pages/independentPages/AidsManagement/Stellantis.vue'),
      },

      ]
  },
   /** Gestion Client **/
  {
    path: '/gestion/client/:num_cmd',
    name: 'GestionClient',
    component: IframeLayout,
    children: [
      {
        path: '',
        name: 'aidsManagement',
        component: () => import('@/pages/independentPages/Client'),
      },
    ]
  }, /** ventes additionnelles **/
  {
    path: '/gestion/vente-add/:num_cmd',
    name: 'AdditionalSales',
    component: IframeLayout,
    component: () => import('@/pages/independentPages/AdditionalSales'),
  },
   /** Validation CDV **/
  {
    path: '/gestion/validation/:num_cmd',
    name: 'validationCdv',
    component: IframeLayout,
    component: () => import('@/pages/independentPages/ValidationCdv'),
  },
  {
    path: '/gestion/import-bdc/:num_cmd?',
    name: 'importBdc',
    component: IframeLayout,
    component: () => import('@/pages/independentPages/ImportBdc'),
  },

  // dashboard & edit profile
  {
    path: '/',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [...mainRoutes],
  },
  // GESTION DE LIVRAISIONS.
  {
    path: '/:lang/stock-vo',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'entreeVO',
        component: () => import('@/pages/stockVO/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Entrées VO' },
          ],
          // CDV,SEC ET ADMIN
          roles: [5,9,10,11],
        },
      },
      {
        path: 'add',
        name: 'addStock',
        component: () => import('@/pages/stockVO/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Nouveau Stock VO' },
          ],
          roles: [5,9,10,11],
        },
      },
      {
        path: 'showStock/:num_cmd',
        name: 'showStockDetails',
        component: () => import('@/pages/secretaire/folders-managementVO/Add'),
        props: {profil: 7},
        meta: {
          breadcrumb: [
            {text: 'Dashboard', href: '/'},
            {text: 'Bons de commande', href: '/orders'},
            {text: 'Commande C1545'},
          ],
          roles: [5, 9, 10, 11],
        }
      }
    ],
  },
    //newCommandes


  {
    path: '/:lang/commandes',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'newCommandes',
        component: () => import('@/pages/vendeur/newCommandes/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Bons de commande' },
          ],
          roles: [1, 2,5]
        },
      },
      {
        path: 'show',
        name: 'NewCommandesDetails',
        component: () => import('@/pages/vendeur/folders-management/Add'),
        props: { profil: 10 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Gestion des Commandes',  to: { name: 'newCommandes' } },
            { text: 'Nouveau commande' },
          ],
          // CDV,SEC ET ADMIN
          roles: [1, 2,5],
        },
      },{
        path: 'site/:ref_site',
        name: 'newCommandesBySite', //showLivraisonDetails
        component: () => import('@/pages/vendeur/newCommandes/Index'),
         meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Gestion des Commandes', to: { name: 'newCommandes' }},
            { text: '' },
          ],
          roles: [1, 2,5]
        },
      },
      {
        path: 'site/:ref_site/new',
        name: 'createCommandesBySite', //showLivraisonDetails
        component: () => import('@/pages/vendeur/folders-management/FolderShow'),
        props: { profil: 10 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Gestion des Commandes', to: { name: 'newCommandes' }},
            { text: '' },
            { text: '' },
          ],
          // CDV,SEC ET ADMIN
          roles: [1, 2,5],
        },
      },
      {
        path: 'site/:ref_site/show/:num_cmd?',
        name: 'showNewCommandesDetails', //showLivraisonDetails
        component: () => import('@/pages/vendeur/folders-management/FolderShow'),
        props: { profil: 10 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Gestion des Commandes', to: { name: 'newCommandes' }},
            { text: '' },
            { text: '' },
          ],
          // CDV,SEC ET ADMIN
          roles: [1, 2,5],
        },
      },
      {
        path: 'site/:ref_site/show/:num_cmd?/detail',
        name: 'showNewCommandesDetails', //showLivraisonDetails
        component: () => import('@/pages/vendeur/folders-management/FolderShow.vue'),
        props: { profil: 10 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Gestion des Commandes', to: { name: 'newCommandes' }},
            { text: '' },
            { text: '' },
          ],
          // CDV,SEC ET ADMIN
          roles: [1, 2,5],
        },
      }
    ]
  },
  // commandes
  {
    path: '/:lang/orders',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'commandes',
        component: () => import('@/pages/commandes/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Bons de commande' },
          ],
          roles: [1, 2,5]
        },
      },
      {
        path: '/orders-vo',
        name: 'commandesVO',
        component: () => import('@/pages/commandesVO/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Bons de commande VO' },
          ],
          roles: [9, 10,5]
        },
      },
      {
        path: 'add',
        name: 'addCommande',
        component: () => import('@/pages/commandes/Add'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Bons de commande',  to: { name: 'commandes' } },
            { text: 'Nouveau bon de commande' },
          ],
          roles: [5,3,2,1],
        },
      },
      {
        path: ':num_cmd',
        name: 'showCommande',
        component: () => import('@/pages/commandes/Add'),
        props: { profil: 2 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Bons de commande',to: { name: 'commandes' } },
            { text: 'Commande C1545' },
          ],
          roles: [1, 2, 3,5],
        },
      },
      {
        path: 'show/:num_cmd',
        name: 'showCommandeDetails',
        component: () => import('@/pages/secretaire/folders-management/Add'),
        props: { profil: 3 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Préparation des livraisons', to: { name: 'commandes' }  },
            { text: 'Commande C1545' },
          ],
          roles: [1, 2, 3,5],
        },
      },
      {
        path: 'showVO/:num_cmd',
        name: 'showCommandeDetailsVO',
        component: () => import('@/pages/secretaire/folders-managementVO/Add'),
        props: { profil: 8 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Bons de commande', to: { name: 'commandes' }  },
            { text: 'Commande C1545' },
          ],
          roles: [5, 9,10,11],
        },
      },
      {
        path: ':id/:tab',
        name: 'showCommandeTab',
        component: () => import('@/pages/commandes/Add'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Bons de commande', to: { name: 'commandes' } },
            { text: 'Commande C1545' },
          ],
          roles: [1, 2, 3,5],
        },
      },
      {
        path: 'statistics',
        name: 'statsCommandes',
        component: () => import('@/pages/commandes/Stats'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Bons de commande', to: { name: 'commandes' }  },
            { text: 'Statistiques' },
          ],
          roles: [1, 2, 3,5],
        },
      },


    ],
  },
  {
    path: '/:lang/facture/:ref_site',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'FactureUnmarkedindex',
        component: () => import('@/pages/facture/unmarked.vue'),
        meta: {
          breadcrumb: [{ text: 'Dashboard', href: '/' }, { text: 'Animation' },{ text: 'Fiche' },],
        },
      },{
        path: 'non-contremarque',
        name: 'factureUnmarked',
        component: () => import('@/pages/facture/unmarked.vue'),
        meta: {
          breadcrumb: [{ text: 'Dashboard', href: '/' }, { text: 'Facture non contremarqué' }],
        },
      },
    ],
  },
  // animation
  {
    path: '/:lang/animation/:ref_site',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'animationSellerSheet',
        beforeEnter(to, from, next) {
         // console.log('To ==> ', to, 'From ==> ', from, this);

          next(vm => {
           //console.log("vm ==> ",vm)
          })
        },
        component: () => import('@/pages/animation/SellerSheet.vue'),
        meta: {
          breadcrumb: [{ text: 'Dashboard', href: '/' }, { text: 'Animation' },{ text: 'Fiche' },],
        },
      },{
        path: 'follow',
        name: 'animationFollow',
        component: () => import('@/pages/animation/Follow.vue'),
        meta: {
          breadcrumb: [{ text: 'Dashboard', href: '/' }, { text: 'Animation' },{ text: 'Suivi' },],
        },
      },{
        path: 'team/:team/function/:function/user/:user/year/:year/month/:month',
        name: 'animationSellerSheetModePreview',
        component: () => import('@/pages/animation/SellerSheet.vue'),
        meta: {
          breadcrumb: [{ text: 'Dashboard', href: '/' }, { text: 'Animation' },{ text: 'Suivi' },],
        },
      },
    ],
  },
  // GESTION DE LIVRAISIONS.

  {
    path: '/:lang/preparation-livrasion',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'documents',
        component: () => import('@/pages/documents/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Préparation des livraisons'},
          ],
          // CDV,SEC ET ADMIN
          roles: [3,5,2],
        },
      },
      {
        path: '/preparation-vo',
        name: 'documentsVO',
        component: () => import('@/pages/documentsVO/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Gestion des commandes VO' },
          ],
          // CDV,SEC ET ADMIN
          roles: [5,10,11],
        },
      },
      {
        path: 'site/:ref_site',
        name: 'documentsWithSite',
        component: () => import('@/pages/documents/Index'),
        props: { },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Préparation des livraisons', to: { name: 'documents' }  },
            { text: '' },
          ],
          roles: [1, 2, 3,5],
        },
      },
      {
        path: 'site/:ref_site/show/:num_cmd',
        name: 'showCommandeDetailsWithSite',
        component: () => import('@/pages/secretaire/folders-management/Add'),
        props: { profil: 3 , routeSite: 'documentsWithSite'},
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Préparation des livraisons', to: { name: 'documents' }  },
            { text: '' },
            { text: '' },
          ],
          roles: [1, 2, 3,5],
        },
      },
    ],
  },

  // livraison
  {
    path: '/:lang/suivi-livraison',

    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'gestionLivraison',
        component: () => import('@/pages/secretaire/livraisons/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Suivi après livraison' },
          ],
           // CDV,SEC ET ADMIN
          roles: [3,5,2],
        },
      },
      {
        path: '/suivi-vo',
        name: 'gestionLivraisonVO',
        component: () => import('@/pages/secretaire/livraisonsVO/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Gestion des livraisons VO' },
          ],
          // CDV,SEC ET ADMIN
          roles: [5,10,11],
        },
      },
      {
        path: 'show/:num_cmd',
        name: 'showLivraisonDetails',
        component: () => import('@/pages/secretaire/folders-management/Add'),
        props: { profil: 4 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Suivi après livraison', to: { name: 'gestionLivraison' } },
            { text: 'Commande C1545' },
          ],
           // CDV,SEC ET ADMIN
          roles: [3,5,2],
        },
      },
      {
        path: 'site/:ref_site',
        name: 'gestionLivraisonWithSite',
        component: () => import('@/pages/secretaire/livraisons/Index'),
        props: { profil: 4 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Suivi après livraison', to: { name: 'gestionLivraison' } },
            { text: '' },
          ],
          // CDV,SEC ET ADMIN
          roles: [3,5,2],
        },
      },
      {
        path: 'site/:ref_site/show/:num_cmd',
        name: 'showLivraisonDetailsWithSite',
        component: () => import('@/pages/secretaire/folders-management/Add'),
        props: { profil: 4 ,routeSite: 'gestionLivraisonWithSite'},
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Suivi après livraison', to: { name: 'gestionLivraison' } },
            { text: '' },
            { text: '' },
          ],
          // CDV,SEC ET ADMIN
          roles: [3,5,2],
        },
      },
      {
        path: 'showVO/:num_cmd',
        name: 'showLivraisonDetailsVO',
        component: () => import('@/pages/secretaire/folders-managementVO/Add'),
        props: { profil: 9 },
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Gestion des livraisons', href: '/livraisonsVO' },
            { text: 'Commande C1545' },
          ],
          // CDV,SEC ET ADMIN
          roles: [5,10,11],
        },
      }
    ],
  },

  // PLANNING
  {
    path: '/:lang/planning/:ref_site/:planning_id',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'planning',
        component: () => import('@/pages/secretaire/delivery-schedule/prestations/Index'),
        // props: route => ({ query: route.query.q }),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Planning' },
          ],
          // CDV , VENDEUR, SEC ET ADMIN
          roles: [3,5,1,2],
        },
      },
    ],
  },

  // SETTINGS
  {
    path: '/:lang/settings/:ref_site',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'settings',
        component: () => import('@/pages/secretaire/delivery-schedule/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Paramétrages' },
          ],

          roles: [3,5], //vendeur et secretaire
        },
      },
    ],
  },

  //PORTFOLIO ASSIGNMENT
   {
      path: '/:lang/portfolio-assignment/:ref_site',
      component: layout,
      meta: {
        loginRequired: true,
      },
      children: [
        {
          path: '',
          name: 'portfolioAssignment',
          component: () => import('@/pages/secretaire/portfolio-assignment/Index'),
          meta: {
            breadcrumb: [
              { text: 'Dashboard', href: '/' },
              { text: 'Affectation portefeuille' },
            ],
            roles: [3,5,2], //secretaire et chef de ventes
          },
        },
      ],
    },
  {
    path: '/:lang/portfolio-assignmentVO/:ref_site',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'portfolioAssignmentVO',
        component: () => import('@/pages/secretaire/portfolio-assignmentVO/Index'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'Affectation portefeuille' },
          ],
          roles: [5,10,11], //secretaire et chef de ventes
        },
      },
    ],
  },
   //Gestion commerciale
    {
      path: '/:lang/business-management/:ref_site',
          component: layout,
          meta: {
            loginRequired: true,
          },
          children: [
            {
              path: '',
              name: 'busniessManagement',
              component: () => import('@/pages/secretaire/business-management/Index'),
              meta: {
                breadcrumb: [
                  { text: 'Dashboard', href: '/' },
                  { text: 'Gestion commerciale' },
                ],
                roles: [3,5,2], //secretaire et chef de ventes
              },
            },
          ],
        },
   {
    path: '/:lang/',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: 'commingSoonGL',
        name: 'commingSoonGL',
        component: () => import('@/pages/samples/error-pages/commingSoonGL'),
        meta: {
          roles: [3], // ONLY SEC
        },
      },
      {
        path: 'empty-planning',
        name: 'emptyData',
        component: () => import('@/pages/samples/error-pages/emptyData'),
        meta: {
          breadcrumb: []
        },
      },
    ],
  },
  // MESSAGES
  {
    path: '/:lang/messages',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'messages',
        component: () => import('@/pages/messages/Index'),
        meta: {
          breadcrumb: [{ text: 'Dashboard', href: '/' }, { text: 'Messages' }],
          roles: [5],
        },
      },
      {
        path: 'add',
        name: 'addMessage',
        component: () => import('@/pages/messages/Add'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'messages', href: '/messages' },
            { text: 'Créer un message' },
          ],
          roles: [5,1,2,3],
        },
      },
      {
        path: 'edit/:id',
        name: 'editMessage',
        component: () => import('@/pages/messages/Edit'),
        meta: {
          breadcrumb: [
            { text: 'Dashboard', href: '/' },
            { text: 'messages', href: '/messages' },
            { text: 'Édition un message' },
          ],
          roles: [5],
        },
      },
    ],
  },

  // Sales objectives
  {
    path: '/:lang/sales-objectives/:ref_site',
    component: layout,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'salesObjectives',
        component: () => import('@/pages/salesObjectives/Index'),
        meta: {
          breadcrumb: [{ text: 'Dashboard', href: '/' }, { text: 'Objectifs' }],
          roles: [5],
        },
      }
    ],
  },
  //error pages
  {
    path: '*',
    redirect: {name: 'error-404', params: { lang: localStorage.getItem('lang')}},
    // component: {
    //     render(c) { return c('router-view') }
    // },
    component: UserLayout,
    // component: () => import('@/layout/UserLayout'),
    children: [...errorsRoutes],
  },
]
const router = new VueRouter({
  linkActiveClass: 'active',
  routes,
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: '/',
})

router.beforeEach(AuthGuard)
export default router
