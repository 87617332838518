import { apiUrl} from '../../constants/config'
import axios from 'axios'

export default {
  state: {
    dashboardKPI:[],
    loadingKPI:false,
    KPIErrors:false
  },
  getters: {
    dashboardKPI: state => state.dashboardKPI,
    loadingKPI : state => state.loadingKPI,
    KPIErrors : state => state.KPIErrors
  },
  mutations: {
    SET_DASHBOARD_KPI(state, payload){
        state.dashboardKPI = payload
      },
    SET_LOADING_KPI(state, payload){
        state.loadingKPI = payload
      },
    SET_DASHBOARD_KPI_ERROR(state, payload){
        state.KPIErrors = payload
      },
  },
  actions: {
    // GET KPIS DASHBOARD LIST (CMDS STATS).
    getDashboardKPI({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING_KPI",true)
            axios.post(apiUrl+'/api/admin/v1/kpi', payload)
                .then(res => {
                        if (res.status === 200 || res.status === 201) {
                         if (res.data.data) {
                             commit("SET_DASHBOARD_KPI",res.data.data);
                         }
                            resolve(res.data)
                            commit("SET_LOADING_KPI",false)
                           // commit("SET_DASHBOARD_KPI",res.data.data);
                            commit("SET_DASHBOARD_KPI_ERROR",false)
                        } else {
                            resolve(null);
                        }

                    })
                .catch(error => {
                  console.log("error",error);
                  commit("SET_LOADING_KPI",false)
                  commit("SET_DASHBOARD_KPI",[]);
                  commit("SET_DASHBOARD_KPI_ERROR",true)
                  reject(error)
                })
        })
    },
  },
}
