import axios from "axios";
import {apiUrl} from "@/constants/config";

export default {
    state: {
        loadingRenameFiles: false,
        ListDocuementLocal: [],
        loadingDocuementLocal: false,
        loadingmAKESIGNEDeFiles: false,
    },
    getters: {
        loadingRenameFiles: state => state.loadingRenameFiles,
        loadingDocuementLocal: state => state.loadingDocuementLocal,
        ListDocuementLocal: state => state.ListDocuementLocal,
        loadingmAKESIGNEDeFiles: state => state.loadingmAKESIGNEDeFiles,
    },
    mutations: {
        SET_LOADING_RENAME_FILES(state, payload) {
            state.loadingRenameFiles = payload
        },
        SET_LOADING_MAKE_SIGNED_FILES(state, payload) {
            state.loadingmAKESIGNEDeFiles = payload
        },
        SET_LOADING_DOCUMENTS_LOCAL(state, payload){
            state.loadingDocuementLocal = payload
        },
        SET_LIST_DOCUMENTS_LOCAL(state, payload) {
            state.ListDocuementLocal = payload
        }
    },
    actions: {
        addDocumentsLocalToFolder(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('SET_LOADING_DOCUMENTS_LOCAL', true);
                axios.post(apiUrl + `/api/admin/v1/document-local/folder/${payload.idfolder}`,payload).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        context.commit('SET_LOADING_DOCUMENTS_LOCAL', false);
                    }
                    resolve(true)
                }).catch((error) => {
                    context.commit('SET_LOADING_DOCUMENTS_LOCAL', false);
                    reject(error);
                })
            })
        },
        deleteDocumentsLocal(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('SET_LOADING_DOCUMENTS_LOCAL', true);
                axios.delete(apiUrl + `/api/admin/v1/document-local/${payload.ref_doc}/folder/${payload.folder}`).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        context.commit('SET_LOADING_DOCUMENTS_LOCAL', false);
                    }
                    resolve(true)
                }).catch((error) => {
                    context.commit('SET_LOADING_DOCUMENTS_LOCAL', false);
                    console.log(error)
                    reject(error);
                })
            })
        },
        documentsRename(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('SET_LOADING_RENAME_FILES', true);
                axios.post(apiUrl + `/api/admin/v1/documents/rename`, payload).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        context.commit('SET_LOADING_RENAME_FILES', false);
                    }
                    resolve(true)
                }).catch((error) => {
                    context.commit('SET_LOADING_RENAME_FILES', false);
                    console.log(error)
                    reject(error);
                })
            })
        },
        documentsMakeSigned(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('SET_LOADING_MAKE_SIGNED_FILES', true);
                axios.post(apiUrl + `/api/admin/v1/vialink/initialize-signature`, payload).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        context.commit('SET_LOADING_MAKE_SIGNED_FILES', false);
                    }
                    resolve(true)
                }).catch((error) => {
                    context.commit('SET_LOADING_MAKE_SIGNED_FILES', false);
                    console.log(error)
                    reject(error);
                })
            })
        },
        documentsGenerate(context, payload) {
            return new Promise((resolve, reject) => {
                axios.get(apiUrl + `/api/common/v1/folder/${payload.num_cmd}/editique/${payload.ref_document}`, payload).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error);
                })
            })
        }
    }
}
