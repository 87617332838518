export const getCurrentUser = () => {
    let user = null;
    try {
        user = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null;
    } catch (error) {
        console.log("getCurrentUser -> error", error)
        user = null;
    }
    return user;
}

export const setCurrentUser = (user) => {
    try {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user))

        } else {
            localStorage.removeItem('user');
            localStorage.removeItem('isClient');

        }
    } catch (error) {
        console.log("setCurrentUser -> error", error)
    }
}

export const checkClientRole = () => {
    try {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        if (user) {3
            return user.fonction.id == 4 ? true : false;
        }
    } catch (error) {
        console.log("ERROR ROLE CLIENT", error)
    }
}

export const checkSecretaireRole = () => {
    try {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        if (user) {
            return user.fonction.id == 3 ? true : false;
        }
    } catch (error) {
        console.log("ERROR ROLE SECRETAIRE", error)
    }
}

export const checkCDVRole = () => {
    try {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        if (user) {
            return user.fonction.id == 2 ? true : false;
        }
    } catch (error) {
        console.log("ERROR ROLE CDV", error)
    }
}




