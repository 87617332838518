import { apiUrl, isAuthGuardActive } from '../../constants/config'
import axios from 'axios'
const { CancelToken } = axios;
let MOdeleListCancel;
export default {
    state: {
        folderList: {},
        durationsList: {},
        availblesDates: {},
        nexDate: new Date(),
        successReservation: false,
        loadAttachmentData: {},
        statusList: {},
        folderDetails: null,
        folderSteps: [],
        LoadingAides: false,
        LoadingAccesoires: false,
        activeManuelleCreate: false,
        sites: [],
        site: null,
        groups: [],
        animationDate: null,
        successAnimationDateLoad: null,
        animationDateLoader: false,
        creationDate: null,
        vendeurs: [],
        loadingAssignementsAction: false,
        successAssignementsAction: false,
        allActionsPlanMessages: [],
        folder: {},
        users: [],
        setSuccessPotentielDate: false,
        loadingProcessPotentiel: false,
        foldersNumberListByClient: [],
        initfilterOrderMangement: [],
        clientFoldersList: [],
        loadingClientFoldersList: false,
        foldersTypesList: [],
        docsList: [],
        createManagamentWithoutBDC: false,
        brand: "",
        progressLoadSaveDetails: null,
        successSavevehcDetails: null,
        KeysDatesLines: [],
        actionsPlansList: [],
        refDocList: [],
        dataFilter: null,
        modelesList: [],
    },
    getters: {
        modelesList: state => state.modelesList,
        folderList: state => state.folderList,
        activeManuelleCreate: state => state.activeManuelleCreate,
        initfilterOrderMangement: state => state.initfilterOrderMangement,
        durationsList: state => state.durationsList,
        availblesDates: state => state.availblesDates,
        nexDate: state => state.nexDate,
        successReservation: state => state.successReservation,
        loadAttachmentData: state => state.loadAttachmentData,
        statusList: state => state.statusList,
        folderDetails: state => state.folderDetails,
        folderSteps: state => state.folderSteps,
        LoadingAides: state => state.LoadingAides,
        LoadingAccesoires: state => state.LoadingAccesoires,
        sites: state => state.sites,
        site: state => state.site,
        groups: state => state.groups,
        vendeurs: state => state.vendeurs,
        secretaires: state => state.secretaires,
        animationDate: state => state.animationDate,
        successAnimationDateLoad: state => state.successAnimationDateLoad,
        animationDateLoader: state => state.animationDateLoader,
        creationDate: state => state.creationDate,
        users: state => state.users,
        loadingAssignementsAction: state => state.loadingAssignementsAction,
        successAssignementsAction: state => state.successAssignementsAction,
        allActionsPlanMessages: state => state.allActionsPlanMessages,
        folder: state => state.folder,
        setSuccessPotentielDate: state => state.setSuccessPotentielDate,
        loadingProcessPotentiel: state => state.loadingProcessPotentiel,
        foldersNumberListByClient: state => state.foldersNumberListByClient,
        foldersTypesList: state => state.foldersTypesList,
        clientFoldersList: state => state.clientFoldersList,
        loadingClientFoldersList: state => state.loadingClientFoldersList,
        docsList: state => state.docsList,
        createManagamentWithoutBDC: state => state.createManagamentWithoutBDC,
        brand: state => state.brand,
        progressLoadSaveDetails: state => state.progressLoadSaveDetails,
        successSavevehcDetails: state => state.successSavevehcDetails,
        KeysDatesLines: state => state.KeysDatesLines,
        actionsPlansList: state => state.actionsPlansList,
        refDocList: state => state.refDocList,
        dataFilter: state => state.dataFilter

    },
    mutations: {
        SET_REF_DOC_LIST(state, payload) {
            state.refDocList = payload;
        },
        SET_FOLDER_LIST(state, payload) {
            state.folderList = payload;
        },
        SET_DURATION_LIST(state, payload) {
            state.durationsList = payload;
        },
        SET_AVAILBLES_DATES_LIST(state, payload) {
            state.availblesDates = payload;
        },
        SET_NEXT_DATE(state, payload) {
            state.nexDate = payload;
        },
        SET_SUCCESS_RESERVATION(state, payload) {
            state.successReservation = payload;
        },
        SET_ATTACHMENTS_DATA(state, payload) {
            state.loadAttachmentData = payload
        },
        SET_STATUS_LIST(state, payload) {
            state.statusList = payload
        },
        SET_FOLDER_DETAILS(state, payload) {
            state.folderDetails = payload
        },
        SET_FOLDER_STEPS(state, payload) {
            state.folderSteps = payload
        },
        SET_FOLDER(state, payload) {
            state.folder = payload
        },
        SET_ACTIVE_MANUELLE_CREATE(state, payload){
            state.activeManuelleCreate = payload
        },
        SET_LOADING_AIDES(state, payload) {
            state.LoadingAides = payload
        },
        SET_LOADING_ACCESSOIRES(state, payload) {
            state.LoadingAccesoires = payload
        },
        SET_SITES_LIST(state, payload) {
            state.sites = payload
        },
        SET_SELECTED_SITE(state, payload) {
            state.site = payload
        },
        SET_GROUPS_LIST(state, payload) {
            state.groups = payload
        },
        SET_VENDEURS_LIST(state, payload) {
            state.vendeurs = payload
        },
        SET_SECRETAIRES_LIST(state, payload) {
            state.secretaires = payload
        },
        SET_USERS_LIST(state, payload) {
            state.users = payload
        },
        SET_ANIMATION_DATE(state, payload) {
            state.dateAnimation = payload
        },
        SET_SUCCESS_ANIMATION_DATE(state, payload) {
            state.successAnimationDateLoad = payload
        },
        SET_LOADING_ANIMATION_DATE(state, payload) {
            state.animationDateLoader = payload
        },
        SET_CREATION_FOLDER_DATE(state, payload) {
            state.creationDate = payload
        },
        SET_LODING_ASSIGN_ACTION(state, payload) {
            state.loadingAssignementsAction = payload
        },
        SET_SUCCESS_ASSIGN_ACTION(state, payload) {
            state.successAssignementsAction = payload
        },
        SET_ACTIONS_PLAN_MESSAGES(state, payload) {
            state.allActionsPlanMessages = payload
        },
        SET_SUCCESS_POTENTIEL_DATE(state, payload) {
            state.setSuccessPotentielDate = payload
        },
        SET_LOADING_PROCESS_POTENTIEL(state, payload) {
            state.loadingProcessPotentiel = payload
        },
        SET_FOLDERS_NUMBERS_LIST(state, payload) {
            state.foldersNumberListByClient = payload
        },
        SET_INIT_FILTER_ORDER_MANAGEMENT(state, payload) {
            const tmpData = [...state.initfilterOrderMangement];
            tmpData[payload.profil_id] = payload.data;
            console.log('tmpData ==> ', tmpData)
            state.initfilterOrderMangement= [...tmpData]
        },
        SET_FOLDER_TYPES_LIST(state, payload) {
            state.foldersTypesList = payload
        },
        SET_FOLDERS_LIST(state, payload) {
            state.clientFoldersList = payload
        },
        SET_LOADER_FOLDERS_CLIENT_LIST(state, payload) {
            state.loadingClientFoldersList = payload
        },
        SET_DOCS_LIST(state, payload) {
            state.docsList = payload
        },
        SET_SUCCESS_CREATION_MANGMENT(state, payload) {
            state.createManagamentWithoutBDC = payload
        },
        SET_VEH_BRAND(state, payload) {
            state.brand = payload
        },
        SET_LOADING_SAVE_VEH_DETAILS(state, payload) {
            state.progressLoadSaveDetails = payload
        },
        SET_SUCCESS_SAVE_VEH_DETAILS(state, payload) {
            state.successSavevehcDetails = payload
        },
        SET_KEYS_DATES_LIST(state, payload) {
            state.KeysDatesLines = payload
        },
        SET_ACTIONS_PLANS_LIST(state, payload) {
            state.actionsPlansList = payload
        },
        SET_INIT_FILTER_DATA(state, payload) {
            state.dataFilter = payload
        },
        SET_MODELES_LIST(state, payload) {
            state.modelesList = payload
        },
    },
    actions: {
        getRefDocuments({commit}, payload) {
            axios
                .get(apiUrl + '/api/admin/v1/getDocument', {
                    params: payload
                })
                .then((response) => {
                    commit('SET_REF_DOC_LIST', response.data);
                })
                .catch((error) => console.log(error))
        },
        //SET POTENTIEL
        setPotentielDate({commit}, payload) {
            commit('SET_LOADING_PROCESS_POTENTIEL', true)
            axios
                .post(apiUrl + '/api/common/v1/update/status/folder/' + payload.folder_id, {potentiel: payload.potentiel_date})
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit('SET_SUCCESS_POTENTIEL_DATE', true)
                        commit('SET_LOADING_PROCESS_POTENTIEL', false)
                    }
                })
                .catch((error) => {
                    commit('SET_SUCCESS_POTENTIEL_DATE', false)
                    commit('SET_LOADING_PROCESS_POTENTIEL', false)
                    console.log(error.message)
                })
        },
        // GET DURATION LIST (AFFECTATION DLL)
        async getSitesList({commit}, payload) {
            let params = {
                site: 1
            }
            await axios
                .get(apiUrl + "/api/admin/v1/groups", {params})
                .then((response) => {
                    commit('SET_GROUPS_LIST', response.data.data)
                })
                .catch((error) => console.log(error));
        },
        // GET USERS LIST (VEND ROLE).
        async getVendeurList({commit}, payload) {
            /* group_filter */
            await axios
                .get(apiUrl + "/api/admin/v1/get/users-lists", {params: payload})
                .then((response) => {
                    commit('SET_VENDEURS_LIST', response.data)
                })
                .catch((error) => console.log(error));
        },
        // GET USERS LIST (SEC ROLE).
        async getSecretaireList({commit}, payload) {
            await axios
                .get(apiUrl + "/api/admin/v1/get/users-lists", {params: payload})
                .then((response) => {
                    commit('SET_SECRETAIRES_LIST', response.data)
                })
                .catch((error) => console.log(error));
        },

        async getDurationList({commit}, payload) {
            await axios.get(apiUrl + '/api/admin/v1/calendar/prestation/' + payload).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    commit('SET_DURATION_LIST', response.data)
                }
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        // GET DURATION LIST (AFFECTATION DLL)
        async getAvailblesDatesList({commit}, payload) {
            await axios.post(apiUrl + '/api/admin/v1/calendar', payload).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    commit('SET_AVAILBLES_DATES_LIST', response.data)
                }
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        // REQUEST FROM RESERVATION LIVRASION DATE.
        saveReservation(context, payload) {
            let resultSave = new Promise((resolve, reject) => {
                axios.post(apiUrl + '/api/admin/v1/calendar/reservation', payload)
                    .then((response) => {
                        context.commit('SET_SUCCESS_RESERVATION', true)
                        resolve(true);
                    })
                    .catch(function (error) {
                        context.commit('SET_SUCCESS_RESERVATION', false)
                        reject(error);
                    });
            });
            return resultSave;
        },
        // SET FOLDER STATUS.
        editFolderStatus(context, payload) {
            axios.post(apiUrl + `/api/common/v1/update/status/folder/${payload.folder}`, {"status": payload.status}).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    context.commit('UPDATE_FOLDER_ITEM', {id: payload.folder, item: response.data})
                }
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        //GET STATUS DISTRUBUTION LIST.
        getStatusList(context, payload) {
            new Promise((resolve, reject) => {
                axios.get(apiUrl + '/api/admin/v1/listStatuses',
                    {
                        params: {
                            type_statut: payload
                        },
                    },)
                    .then((response) => {
                        context.commit('SET_STATUS_LIST', response.data)
                        resolve(true);
                    })
                    .catch(function (error) {
                        // context.commit('SET_SUCCESS_RESERVATION', false)
                        reject(error);
                    });
            });
        },
        getFolderSteps(context, payload) {
            return new Promise((resolve, reject) => {
                axios.get(apiUrl + `/api/common/v1/folder/${payload.folderId}/workflow/profile/${payload.profileId}`, {
                    params: {
                        profile_id: payload.profileId
                    }
                }).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        context.commit('SET_FOLDER_STEPS', response.data.data);

                    }
                    resolve(true)
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        activeManuelleCreate(context, payload) {
            console.log('activeManuelleCreate ==> ', payload)
            context.commit('SET_ACTIVE_MANUELLE_CREATE', payload);
        },
        disableManuelleCreate(context, payload) {
            console.log('disableManuelleCreate ==> ')
            context.commit('SET_ACTIVE_MANUELLE_CREATE', false);
        },
        updateFolderAccessoires(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('SET_LOADING_ACCESSOIRES', true);
                let params = {
                    profile_id: payload.profileId ?? 0,
                };
                axios.post(apiUrl + `/api/admin/v1/folder/${payload.id}/accessoire`, payload).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        context.commit('SET_LOADING_ACCESSOIRES', false);
                        context.dispatch('getFolderDetails', payload);
                    }
                    resolve(response.data)
                }).catch((error) => {
                    context.commit('SET_LOADING_ACCESSOIRES', false);
                    console.log(error)
                    reject(error);
                })
            });
        },
        folderVerify(context, payload) {
            return new Promise((resolve, reject) => {
               // axios.get(apiUrl + `/api/admin/v1/folder/${payload.numCmd}/verify`).then((response) => {
                axios.post(apiUrl + `/api/admin/v1/site/${payload.refsite}/folder/verify`, payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {

                    reject(error);
                })
            });

        },
        getFolderDetails(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('SET_LOADING_AIDES', true);
                let params = {
                    profile_id: payload.profileId ?? 0,
                };
                axios.get(apiUrl + `/api/admin/v1/folder/${payload.numCmd}`, {params}).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        context.commit('SET_FOLDER_DETAILS', response.data);
                        context.commit('SET_LOADING_AIDES', false);

                    }
                    resolve(response.data)
                }).catch((error) => {
                    context.commit('SET_LOADING_AIDES', false);
                    console.log(error)
                    reject(error);
                })
            });
        },
        // UPDATE ANIMATION DATE
        setAnimationDate(context, payload) {
            context.commit('SET_LOADING_ANIMATION_DATE', true)
            new Promise((resolve, reject) => {
                axios.post(apiUrl + '/api/common/v1/gestion/date_animation', payload)
                    .then((response) => {
                        context.commit('SET_SUCCESS_ANIMATION_DATE', {status: true, message: response})
                        context.commit('SET_LOADING_ANIMATION_DATE', false)
                        resolve(true);
                    })
                    .catch(function (error) {
                        console.log("err", error)
                        context.commit('SET_SUCCESS_ANIMATION_DATE', {
                            status: false,
                            message: error.response.data.message
                        })
                        context.commit('SET_LOADING_ANIMATION_DATE', false)
                        reject(error);
                    });
            });
        },
        async getUsersList({commit}, payload) {
            await axios
                .get(apiUrl + "/api/admin/v1/get/users-lists", {params: payload})
                .then((response) => {
                    commit('SET_USERS_LIST', response.data)
                })
                .catch((error) => console.log(error));
        },
        // GET DURATION LIST (AFFECTATION DLL)
        async assignFolderUser(context, payload) {
            context.commit('SET_LODING_ASSIGN_ACTION', true)
            await axios.put(apiUrl + '/api/admin/v1/assign/folder/' + payload.id, payload).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    context.commit('SET_LODING_ASSIGN_ACTION', false)
                    context.commit('SET_SUCCESS_ASSIGN_ACTION', true)
                }
            })
                .catch((error) => {
                    context.commit('SET_LODING_ASSIGN_ACTION', false)
                    context.commit('SET_SUCCESS_ASSIGN_ACTION', false)
                    console.log(error)
                })
        },
        getColabList(context, payload) {
            axios.get(apiUrl + '/api/admin/v1/get-users', {
                params: {
                    type: payload,
                },
            })
                .then((response) => {
                    context.commit('SET_USERS_LIST', response.data.data)
                })
                .catch((error) => console.log(error))
        },
        async getFolderNbrListByClient(context, payload) {
            await axios.get(apiUrl + '/api/admin/v1/folders/client/' + payload, {
                params: {
                    num_only: 1,
                },
            })
                .then((response) => {
                    context.commit('SET_FOLDERS_NUMBERS_LIST', response.data)
                })
                .catch((error) => console.log(error))
        },
        getInitFilterOrderManagement(context, payload) {
            return new Promise((resolve, reject) => {
                let listCall = [];
                listCall.push(axios.get(apiUrl + "/api/admin/v1/groups"));
                listCall.push(axios.get(apiUrl + "/api/admin/v1/listStatuses", {
                    params: {
                        profil_id: payload?.profileId ? payload?.profileId: 0,
                    }
                }));
                listCall.push(axios.get(apiUrl + "/api/admin/v1/list-energies"));
                listCall.push(axios.get(apiUrl + "/api/admin/v1/getDocument"));
                listCall.push(axios.get(apiUrl + "/api/admin/v1/typeDossier"));
                listCall.push(axios.get(apiUrl + "/api/admin/v1/folders/date"));
                listCall.push(axios.get(apiUrl + "/api/admin/v1/type-clients"));

                axios.all(listCall).then(axios.spread(({data: groups}, {data: status}, {data: energies}, {data: documents}, {data: foldersTypes}, {data: foldersDates}, {data:clientOptions}) => {
                    context.commit('SET_INIT_FILTER_ORDER_MANAGEMENT', {
                        data:{
                            groups,
                            status,
                            energies,
                            documents,
                            foldersTypes,
                            foldersDates,
                            clientOptions
                        },
                        profil_id: payload?.profileId ? payload.profileId: 0
                    })
                    context.commit('SET_KEYS_DATES_LIST', foldersDates);
                    context.commit('SET_FOLDER_TYPES_LIST', foldersTypes.data)
                    resolve({groups, status, energies, documents, foldersTypes, foldersDates, clientOptions})
                })).catch((err) => {
                    reject(err);
                });
            });
        },
        //GET FOLDER TYPES
        async getFolderTypes({commit}) {
            await axios.get(apiUrl + '/api/admin/v1/typeDossier').then((response) => {
                if (response.status === 200 || response.status === 201) {
                    commit('SET_FOLDER_TYPES_LIST', response.data.data)
                }
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        getFolderListByClient(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('SET_LOADER_FOLDERS_CLIENT_LIST', true)
                axios.get(apiUrl + '/api/admin/v1/folders/client/' + payload.clientID, {
                    params: {
                        delivered: payload.delivered,
                        tab: payload.tab
                    },
                })
                    .then((response) => {
                        context.commit('SET_FOLDERS_LIST', response.data);
                        context.commit('SET_LOADER_FOLDERS_CLIENT_LIST', false)
                        resolve(true)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error);
                        context.commit('SET_LOADER_FOLDERS_CLIENT_LIST', false)
                    })
            })
        },
        // GET DOCS LIST
        getDocsList(context, payload) {
            return new Promise((resolve, reject) => {
                axios.get(apiUrl + '/api/common/v1/dossier_list_documents', {
                    params: payload,
                })
                    .then((response) => {
                        context.commit('SET_DOCS_LIST', response.data);
                        resolve(response.data)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    })
            });
        },
        // CREATION EMPTY MANAGMENT.
        createEmptyMangement({commit}, payload) {
            return new Promise((resolve, reject) => {
            axios
                .post(apiUrl + '/api/common/v1/gestion_without_bdc', payload)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit('SET_SUCCESS_CREATION_MANGMENT', true)
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    commit('SET_SUCCESS_CREATION_MANGMENT', false)
                    reject(error)
                })
            });
        },
        // ADD/SET UP VEH DETAILS.
        saveVehDetails(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('SET_LOADING_SAVE_VEH_DETAILS', true)
                axios
                    .post(apiUrl + "/api/common/v1/dossier_vehicule", payload)
                    .then((response) => {
                        if (response.status === 200 || response.status === 201) {
                            resolve(response)
                            context.commit('SET_SUCCESS_SAVE_VEH_DETAILS', true)
                            context.commit('SET_LOADING_SAVE_VEH_DETAILS', false)
                        }
                    })
                    .catch((error) => {
                        context.commit('SET_LOADING_SAVE_VEH_DETAILS', false);
                        context.commit('SET_SUCCESS_SAVE_VEH_DETAILS', false)
                        console.log(error);
                        reject(error)
                    });
            })
        },

        //GET LINE KEY DATES.
        getLineKeysDates(context, payload) {
            return new Promise((resolve, reject) => {
                axios.get(apiUrl + '/api/admin/v1/folders/date')
                    .then((response) => {
                        context.commit('SET_KEYS_DATES_LIST', response.data);
                        resolve(response.data)

                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    })
            });
        },
        // GET ACTIONS PLAN LIST (PARAMS . CMD NUMBER)
        getActionsPlanByFolder(context, payload) {
            return new Promise((resolve, reject) => {
                axios.get(apiUrl + '/api/common/v1/alert-messages/' + payload)
                    .then((response) => {
                        context.commit('SET_ACTIONS_PLANS_LIST', response.data.data);
                        resolve(response.data.data)

                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    })
            });
        },
        getInitDataFilter(context, payload) {
            return new Promise((resolve, reject) => {
                let listCall = [];
                listCall.push(axios.get(apiUrl + "/api/admin/v1/getDocument"));
                listCall.push(axios.get(apiUrl + "/api/admin/v1/listStatuses", {
                    params: {
                        profil_id: payload.profil_id,
                    }
                }));
                listCall.push(axios.get(apiUrl + "/api/admin/v1/list-energies"));
                listCall.push(axios.get(apiUrl + "/api/admin/v1/groups"));
                listCall.push(axios.get(apiUrl + "/api/admin/v1/folders/date"));
                listCall.push(axios.get(apiUrl + '/api/admin/v1/typeDossier'));

                axios.all(listCall).then(axios.spread(({data: documents}, {data: status}, {data: energies}, {data: groups}, {data: foldersDates}, {data: typeDossier}) => {
                    context.commit('SET_INIT_FILTER_DATA', {
                        groups,
                        status,
                        energies,
                        documents,
                        foldersDates,
                        typeDossier
                    })
                    context.commit('SET_KEYS_DATES_LIST', foldersDates);
                    context.commit('SET_FOLDER_TYPES_LIST', typeDossier.data);
                    resolve({groups, status, energies, documents, foldersDates, typeDossier})
                })).catch((err) => {
                    reject(err);
                });
            });
        },
        searchModele (context, payload) {
            return new Promise((resolve, reject) => {
                if (MOdeleListCancel) {
                    MOdeleListCancel("La requête précédente a été annulée.");
                }
                const source = CancelToken.source();
                MOdeleListCancel = source.cancel;
                axios.get(apiUrl + '/api/common/v1/modele', {
                    cancelToken: source.token,
                    params: payload,
                })
                    .then((response) => {
                        context.commit('SET_MODELES_LIST', response.data);
                        resolve(response.data)

                    })
                    .catch((error) => {
                        reject(error)
                    })
            });
        }

    }
}
